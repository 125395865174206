.bigdata__page {

    .wrapper {
        max-width: 1280px;
        margin-right: auto;
        margin-left: auto;

        @include media-max(1366px) {
            max-width: 90%;
        }
    }
    .hero {
        position: relative;
        margin-top: rem(300px);
        background-size: 100%;
        background-position: center top;
        background-repeat: no-repeat;

        @include media-max(1200px) {
            margin-top: rem(200px);
        }
        @include media-max(960px) {
            min-height: 1px;
        }

        .hero__bg {
            img {
                width: 100%;
            }
        }
        .hero__title {
            position: relative;
            overflow: hidden;

            .title {
                font-family: 'DM Sans';
                font-weight: 700;
                font-size: 16px;
                width: 104%;
                translate: -2% 0;
                
                .large {
                    pointer-events: none;
                    fill: transparent;
                    stroke: #fff;
                    stroke-width: 0.1px;
                    -webkit-user-select: none; /* Safari */        
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */
                }
            }
            .subtitle {
                font-family: 'DM Sans';
                font-weight:400;
                font-size: 16px;
                width: 50%;
                fill:#fff;
                position: absolute;
                left: 39.5%;
                top: 75%;
            }
        }
        .hero__text {
            text-align: center;
            background-position:center;
            background-repeat: no-repeat;
            background-size: 50%;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            margin-top: rem(350px);
            padding-top: rem(150px);
            padding-bottom: rem(150px);

            @include media-max(1200px) {
                margin-top: rem(250px);
            }
            @include media-max(960px) {
                background-size: 100%;
                margin-top: rem(150px);
                padding-top: rem(50px);
                padding-bottom: rem(50px);
            }
            @include media-max(450px) {
                background-size: cover;
                font-size: rem(40px);
            }
        }
    }
    .text {
        border-top: 1px solid $grey80;
        border-bottom: 1px solid $grey80;
        padding-block: rem(150px);
        
        @include media-max(960px) {
            padding-block: rem(50px);
        }

        .grid {
            display: grid;
            grid-template-columns: 2fr 1fr;
            margin: 0;

            @include media-max(960px) {
                grid-template-columns: 1fr;
            }
        }
        strong {
            color: $green;
        }
    }
    .data__scheme {
        position: relative;

        img, svg {
            width: 100%;
            translate: 2px 0px;
        }
    }
    .data {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;

        .data_card_bar {
            max-width: 54vw;
            margin-inline: auto;
            display: block;
        }

        .data__card {
            text-align: center;
            max-width: 54vw;
            width: 100%;
            margin-inline: auto;
            padding: rem(150px 140px 20px);
            background: linear-gradient(180deg, rgba(136, 252, 108, 0.29) 0%, rgba(0, 0, 0, 0.29) 77.92%);
            position: relative;
            
            @include media-max(1200px) {
                width: rem(620px);
                padding: rem(50px 40px 20px);
            }
            @include media-max(960px) {
                width: 96%
            }

            &:before {
                content: '';
                display: block;
                width: 1px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: linear-gradient(180deg, rgba(136, 252, 108, 0.29) 0%, rgba(0, 0, 0, 0.29) 100%);
            }
            &:after {
                content: '';
                display: block;
                width: 1px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                background: linear-gradient(180deg, rgba(136, 252, 108, 0.29) 0%, rgba(0, 0, 0, 0.29) 100%);
            }

            .title {
                background: linear-gradient(94.07deg, #88FC6C 10%, #00FFAC 53.5%, #C7FF2A 102.66%);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;

                @include media-max(450px) {
                    font-size: rem(46px);
                }
            }
    
            .quote {
                margin-top: rem(80px);
                
                .author {
                    text-align: right;
                    @extend .margin_right_50;
                    background: linear-gradient(94.07deg, #00A4EF 14.4%, #6005FE 57.89%, #D93FFF 107.05%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }
            }
    
            .vert__dash {
                display: block;
                width: 1px;
                height: rem(120px);
                background-color: $green;
                margin-inline: auto;
                margin-block: rem(30px);
                
                &.dash180 {
                    height: rem(180px);
                }
            }
        }
    }
    .blocks {

        .title__section {
            text-align: center;
            @extend .t60;
            font-weight: 700;
            background-position: center bottom;
            padding: rem(450px 0 105px);
            position: relative;
            isolation: isolate;

            @include media-max(640px) {
                padding-inline: rem(30px);
                font-size: rem(30px);
            }
            
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left:0;
                width: 100%;
                height: 25%;
                z-index: 3;
                pointer-events: none;
                background: linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);

            }
            .title {
                line-height: 1.7;
            }
            .pink__gradient {
                background: linear-gradient(94.07deg, #FF3D57 14.4%, #DD42FF 57.89%, #FF3D57 107.05%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
            .button__container {
                margin-top: rem(30px);
            }
        }
        .blocks__section {
            display: grid;
            grid-template-columns: repeat(4, minmax(0,1fr));
            
            @include media-max(960px) {
                grid-template-columns: repeat(2, minmax(0,1fr));

                .block:nth-child(1) {
                    order: 1;
                }
                .block:nth-child(2) {
                    order: 2;
                }
                .block:nth-child(3) {
                    order: 4;
                }
                .block:nth-child(4) {
                    order: 3;
                }
                .block:nth-child(6) {
                    order: 5;
                }
                .block:nth-child(5) {
                    order: 6;
                }
                .block:nth-child(7) {
                    order: 7;
                }
                .block:nth-child(8) {
                    order: 8;
                }
            }
            @include media-max(450px) {
                grid-template-columns: repeat(1, minmax(0,1fr));
                border-bottom: 1px solid $grey80;

                .block:nth-child(1) {
                    order: 1;
                }
                .block:nth-child(2) {
                    order: 2;
                }
                .block:nth-child(3) {
                    order: 3;
                }
                .block:nth-child(4) {
                    order: 4;
                }
                .block:nth-child(6) {
                    order: 5;
                }
                .block:nth-child(5) {
                    order: 6;
                }
                .block:nth-child(8) {
                    order: 7;
                }
                .block:nth-child(7) {
                    order: 8;
                }
            }

            .block {
                aspect-ratio: 1/1;
                @extend .t40;
                font-weight: 700;
                display: flex;
                align-items: flex-end;
                align-content: flex-end;

                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;

                .block__inner {
                    padding: rem(50px);
                }
                .block__icon {
                    margin-bottom: rem(32px);
                }
            }
        }
    }
    .bigdata__cards {
        padding-block: rem(220px);
        
        @include media-max(960px) {
            padding-block: rem(120px);
        }

        .cards__grid {
            display: grid;
            grid-template-columns: repeat(2, minmax(0,1fr));
            column-gap:rem(200px);
            row-gap:rem(160px);
            
            @include media-max(960px) {
                column-gap:rem(100px);
                row-gap:rem(60px);
            }
            @include media-max(640px) {
                grid-template-columns: 1fr;
                gap: rem(70px);
            }

            .card {
                .card__title {
                    @extend .t32;
                    font-weight: 700;
                    line-height: 1.4;
                    margin-bottom:rem(50px);

                    @include media-max(640px) {
                        font-size: rem(36px);
                    }

                    .yellow {
                        background: linear-gradient(94.07deg, #FAFD5E 14.4%, #FFB822 57.89%, #FFF62A 107.05%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                    }
                    .sky {
                        background: linear-gradient(153.01deg, #88FC6C 2.53%, #00C2FF 95.86%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                    }
                    .red {
                        background: linear-gradient(94.07deg, #FF3D57 14.4%, #DD42FF 57.89%, #FF3D57 107.05%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                    }
                    .blue {
                        background: linear-gradient(94.07deg, #00A4EF 14.4%, #6005FE 57.89%, #D93FFF 107.05%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                    }
                }
                .card__content {
                    @extend .t14;
                    color: $grey40;
                    line-height: 1.5;

                    @include media-max(640px) {
                        font-size: rem(18px);
                    }

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}