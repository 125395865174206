.single__page {
    .wrapper {
        max-width:1280px;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        
        @include media-max(1440px) {
            max-width:90%;
        }
        &.stick__right {
            max-width:100vw;
            margin-left:calc((100vw - 1280px) / 2);
            
            @include media-max(1440px) {
                margin-left:5%;
            }
            @include media-max(640px) {
                margin-right:5%;
            }
        }

        &.narrow {
            max-width: 1100px;
            margin-right: auto;
            margin-left: auto;
            position: relative;
            isolation: isolate;
            
            @include media-max(1440px) {
                max-width: 90%;
            }
            @include media-max(640px) {
                padding-bottom: rem(60px);
            }
        }
    }
    .contact__pattern {
        position: absolute;
        z-index: -1;
        right:rem(-75px);
        pointer-events: none;
        width:rem(594px);
        
        @include media-max(1440px) {
            right:rem(20px);
        }
        @include media-max(1200px) {
            width:rem(400px);
        }

        @include media-max(640px) {
            img {
                transform: scale(-1, 1);
            }
        }
        
        .text__layer {
            position: absolute;
            top:rem(100px);
            left:rem(200px);
            
            @include media-max(1200px) {
                top:rem(60px);
                left:rem(150px);
            }

            @include media-max(640px) {
                display: none;
            }
        }
    }
}
.contact__page {
    .contact__grid {
        display:grid;
        grid-template-columns: 1fr 2fr 3fr;
        row-gap:rem(70px);
        column-gap:rem(180px);
        
        @include media-max(960px) {
            column-gap:rem(70px);
        }
        @include media-max(640px) {
            grid-template-columns: 1fr;
            gap:rem(40px);
            padding-left:rem(40px);
        }

        p {
            line-height: 1.3;
        }
        a:hover {
            color:$green;
        }

        .contact__data {
            grid-column: 1/-1;
        }
        .col__span2 {
            grid-column: span 2;

            @include media-max(640px) {
                grid-column: span 1;
            }
        }

        .t36 {
            margin-bottom: rem(50px);
            position: relative;

            &:before {
                content:'•';
                color:$green;
                position: absolute;
                top:0;
                bottom:0;
                left:rem(-30px);
                
                @include media-max(960px) {
                    left:rem(-15px);
                }
            }
        }
    }
}
.news__page {
    .news__card {
        border-radius:20px;
        overflow: hidden;
        position: relative;

        @include media-max(960px) {
            width: 100%;
            overflow: hidden;

            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
        }

        &:hover {
            transform: scale(1.02);
        }
        
        & > img {
            width:100%;
            
            @include media-max(960px) {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
                z-index: 6;
            }
        }
        .limit__width33 {
            max-width: 33%;
            
            @include media-max(960px) {
                max-width: 100%;
            }
        }
        .limit__width45 {
            max-width: 45%;

            @include media-max(960px) {
                max-width: 100%;
            }
        }
        .limit__width66 {
            max-width: 66%;

            @include media-max(960px) {
                max-width: 100%;
            }
        }

        .card__padding {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 9;
            top:0;
            left:0;
            display: flex;
            align-items: center;
            align-content: center;

            .card__content {
                padding:rem(50px);
                
                @include media-max(1200px) {
                    padding:rem(30px);
                }
            }
        }
        &.slim__card {
            position: relative;
            width: 100%;

            .card__padding {
                display: flex;
                align-items: flex-end;
                align-content: flex-end;
            }
        }
        &.square__card {
            position: relative;
            width: 100%;

            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }

            .card__padding {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 9;
                top:0;
                left:0;
                display: flex;
                align-items: flex-end;
                align-content: flex-end;
            }
        }
    }
}
.team__page {
    .grid {
        gap:rem(65px);
        margin-top: rem(100px);
    }
    .grid__1_1_1 {
        @include media-max(960px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include media-max(640px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .team__hiring {
        margin-top:rem(300px);
        
        @include media-max(960px) {
            margin-top:rem(150px);
            text-align:center;
            justify-content: center;
            justify-items: center;
        }
    }
    .video__box {
        position: relative;
        text-align:center;
        
        a {
            text-align: center;
            justify-content: center;
            justify-items: center;
        }

        .play__icon {
            position:absolute;
            inset:0;
            margin: auto;
            z-index: 9;
        }
    }
}
.team__card {
    position: relative;

    .card__content {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        display: flex;
        place-items: center;
        place-content: center;
        text-align: center;

        & > div {
            transform: translate(0, 100%);
        }

        .team__position {
            text-transform: uppercase;
            letter-spacing: 4px;
            font-size: rem(12px);
            margin-top: rem(10px);
        }
    }
}
.job__card {
    position: relative;
    color:$grey20;

    & > img {
        width: 100%;
    }

    .card__content {
        position: absolute;
        top:0;
        left:0;
        z-index: 6;
        width: 100%;
        height:100%;
        padding:rem(45px 45px 55px 45px);
        display: flex;
        align-items: flex-end;
        align-content: flex-end;

        .card__desc {
            max-width: 80%;
        }

        .button__container {
            margin-top: rem(40px);
        }
    }
}
.team__bg {
    background-image: url('../../assets/img/team_bg.jpg');
    position: center top;
    background-size: contain;
    background-repeat: no-repeat;
}
.grid {
    display: grid;
    gap:rem(35px);
    margin-bottom: rem(35px);
    overflow-y: hidden;

    a {
        display: flex;
    }
}
.grid__1_1 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    
    @include media-max(640px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
.grid__1_2 {
    grid-template-columns: 1fr 2fr;
    
    @include media-max(640px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
.grid__1_1_1 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    
    @include media-max(960px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @include media-max(640px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
.grid__2_1_1 {
    grid-template-columns: 2fr 1fr 1fr;
    align-items: center;
    align-content: center;
    
    @include media-max(960px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @include media-max(640px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
.about__page {
    .grid {
        .grid {
            height:100%;

            @include media-max(640px) {
                padding: rem(25px);
                flex-wrap: wrap;
                gap:rem(25px);
            }
        }
    }
    .hero {
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 110vh;
        display: flex;
        align-items: center;
        align-content: center;
        
        & > img {
            width:100%;
        }

        .hero__content {
            width: 100%;
            height: 100%;
        }

        &.secondary {

            .wrapper {
                height:calc(100% - 200px);
                width:100%;
                display: grid;
                grid-template-columns: 2fr 2fr 1.2fr;
                grid-template-rows: repeat(3, minmax(0,1fr));
                
                @include media-max(960px) {
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: auto auto;
                }
                @include media-max(640px) {
                    grid-template-columns: 1fr;
                    grid-template-rows: auto;

                    & div {
                        max-width: 60%;
                    }
                }
            }
        }
    }
    .certifications {
        .cert__title {
            position: relative;
            padding-top: rem(200px);
            padding-bottom: rem(150px);
            text-align: center;
            
            @include media-max(960px) {
                padding-top: rem(100px);
                padding-bottom: rem(75px);
            }
            .noodle {
                width:rem(605px);
                position: absolute;
                inset:-9999px;
                margin: auto;

                @include media-max(960px) {
                    width:rem(350px);
                }
            }
        }
    }
    .workingon {
        padding-top:rem(250px);
        padding-bottom:rem(250px);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        
        @include media-max(960px) {
            padding-top:rem(75px);
            padding-bottom:rem(75px);
            // background-image: none !important;
            background-color: $dimmedblack;
        }
        @include media-max(640px) {
            background-image: none !important;
            background-color: $dimmedblack;
        }
 
        .icons__list {
            display: grid;
            gap:rem(25px);
            margin-top: rem(60px);
            margin-bottom: rem(60px);

            .list__item {
                display: grid;
                grid-template-columns: 60px auto;
                gap:rem(15px);
                align-items: center;
                align-content: center;
                text-align: center;

                div {
                    text-align: center;
                    
                    &:not(:first-of-type) {
                        text-align: left;
                    }
                }
            }
        }
    }
    .cert__card {
        border-radius: 40px;
        display: flex;
        place-content: center;
        place-items: center;

        &:not(.featured) {
            @include media-max(960px) {
                img {
                    height:rem(30px);
                }
            }
            @include media-max(640px) {
                img {
                    height:rem(50px);
                }
            }
        }
    }
    .border {
        border-radius: 40px;
        border:1px solid $grey80;
    }
    .flex {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-around;
        padding-top:rem(75px);
        padding-bottom:rem(75px);

        @include media-max(960px) {
            img {
                height:rem(45px);
            }
        }
        @include media-max(640px) {
            padding: rem(25px);
            flex-wrap: wrap;
            gap:rem(25px);

            img {
                height:rem(85px);
            }
        }
    }
    .square__card {
        position: relative;
        width: 100%;

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        .card__padding {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 9;
            top:0;
            left:0;
            display: flex;
            place-content: center;
            place-items: center;
        }
    }
    .cta {
        padding-top: rem(150px);
        padding-bottom: rem(100px);
        
        @include media-max(960px) {
            padding-top: rem(75px);
            padding-bottom: rem(75px);
        }
    }
    .timeline {
        padding-top:rem(250px);
        padding-bottom:rem(250px);
        position: relative;
        background-color: $dimmedblack;
        isolation: isolate;
        
        @include media-max(960px) {
            padding-top:rem(150px);
            padding-bottom:rem(150px);
        }

        &:before {
            content:'';
            display: block;
            width:100%;
            height:1px;
            background-color: $grey80;
            position: absolute;
            inset:0;
            margin: auto;
            z-index: -1;

            @include media-max(640px) {
                width:1px;
                height:100%;
            }
        }

        .wrapper {
            width: 100%;
            display: flex;
            align-content: stretch;
            align-items: stretch;

            @include media-max(640px) {
                flex-direction: column;
            }

            .tl__item {
                width: max-content;
                margin-right: auto;
                display: grid;
                grid-template-rows: repeat(3, rem(100px));
                
                @include media-max(640px) {
                    width: 100%;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    grid-template-rows: repeat(1, rem(100px));
                }

                * {
                    transition: $t-all;
                }
                
                div:nth-child(1) {
                    order:1;
                }
                div:nth-child(2) {
                    order:2;
                }
                div:nth-child(3) {
                    order:3;
                }

                &:nth-child(even) {
                    div:nth-child(1) {
                        order:3;
                    }
                    div:nth-child(3) {
                        order:1;
                    }
                }

                & > div:nth-child(2) {
                    display: flex;
                    align-items: center;
                    align-content: center;

                    @include media-max(640px) {
                        justify-content: center;
                        justify-items: center;
                    }
                }

                .date {
                    margin-bottom:rem(30px);
                }
                .dot {
                    display: block;
                    width:15px;
                    height:15px;
                    border:1px solid $grey80;
                    background-color: $dimmedblack;
                    border-radius: 1000px;
                }

                &:last-child, &:hover {
                    .date {
                        color:$green;
                    }
                    .dot {
                        background-color: $green;
                        border-color:$green;
                    }
                }
            }
        }
    }
    .meettheteam {
        padding-top:rem(150px);
        padding-bottom:rem(150px);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100vh;
        display: flex;
        align-items: center;
        align-content: center;

        .button__container {
            margin-top:rem(55px);

            .button {
                display: inline-block;
            }
        }
    }
}
table {
    border-collapse: collapse;
    border:0;
    margin-bottom: rem(30px);

    tr {
        border:1px solid #fff;

        &:nth-of-type(even) {
            background-color: rgba(255,255,255,0.15);
        }
    }

    th, td {
        padding:rem(13px 8px);
    }
}
.menu__footer {
    display: flex;
    gap:rem(25px);
    flex-wrap: wrap;
}
.gradient1 {
    background: linear-gradient(94.07deg, #88FC6C 10%, #00FFAC 53.5%, #C7FF2A 102.66%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}
.gradient2 {
    background: linear-gradient(94.07deg, #88FC6C -10.68%, #C7FF2A 27.44%, #63B450 62.67%, #31FF00 81.97%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}