.development__page {
    // overflow-y: hidden;

    .red {
        background: linear-gradient(94.07deg, #FF3D57 14.4%, #DD42FF 57.89%, #FF3D57 107.05%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .green:not(.button) {
        background: linear-gradient(153.01deg, #88FC6C 2.53%, #00C2FF 95.86%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        
        .grey {
            background-color: $grey80 !important;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
    .wrapper {
        max-width: 1280px;
        margin-right: auto;
        margin-left: auto;

        @include media-max(1366px) {
            max-width: 90%;
        }
    }
    .hero {
        position: relative;
        isolation: isolate;
        padding-top: rem(200px);
        background-size: 100%;
        background-position: center top;
        background-repeat: no-repeat;
        overflow-y: hidden;

        @include media-max(1200px) {
        }
        @include media-max(960px) {
            min-height: 1px;
            padding-top: rem(120px);
        }
        @include media-max(450px) {
            padding-top: rem(200px);
        }
        .hero__img {
            animation-duration: 1000ms !important;
            position: absolute;
            width: 100%;
            top:0;

            img {
                width: 100%;

                @include media-max(640px) {
                    width: rem(640px);
                    height: rem(440px);
                    object-fit: cover;
                }
            }
        }
        .hill {
            translate: 0;

            img {
                width: 100%;

                @include media-max(1200px) {
                    translate: 0 rem(-30px);
                }
                @include media-max(960px) {
                    translate: 0 0;
                }
                @include media-max(640px) {
                    height: 200px;
                    object-fit: cover;
                }
            }
        }
        .hero__bg {
            img {
                width: 100%;
            }
        }
        .hero__title {
            animation-duration: 700ms !important;
            position: relative;
            overflow: hidden;
            z-index: 9;

            .title {
                font-family: 'DM Sans';
                font-weight: 700;
                font-size: 16px;
                width: 104%;
                translate: -2% 0;
                
                .large {
                    pointer-events: none;
                    fill: transparent;
                    stroke: #fff;
                    stroke-width: 0.1px;
                    -webkit-user-select: none; /* Safari */        
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */
                }
            }
        }
        .hero__subtitle {
            animation-duration: 700ms !important;
            text-align: center;
            position: absolute;
            bottom: rem(100px);
            right: 0;
            left:0;
            margin: auto;

            @include media-max(1200px) {
                bottom: rem(60px);
            }
            @include media-max(960px) {
                bottom: rem(30px);

                & * {
                    font-size:rem(20px) !important;
                }
            }
            @include media-max(450px) {
                bottom: 0;
                text-shadow: 0 0 10px rgba(0,0,0,0.5);
            }
        }
    }
    .what {
        background-size: 100%, 100%;
        background-position: center top, center rem(550px);
        background-repeat: repeat, no-repeat;

        @include media-max(640px) {
            min-height: 1px;
        }

        .main__content {
            padding: rem(350px) 0 rem(680px);
            position: relative;
            
            @include media-max(1200px) {
                padding: rem(250px) 0 rem(580px);
            }
            @include media-max(960px) {
                padding: rem(150px) 0 rem(450px);
            }
            @include media-max(640px) {
                padding: rem(50px) 0 rem(200px);
            }
            @include media-max(450px) {
                padding: rem(0px) 0 rem(200px);
            }

            .quote {
                display: flex;
                align-items: center;
                align-content: center;
                gap: 20px;
                position: absolute;
                bottom: rem(100px);
                z-index: 3;
                color: $grey40;
                line-height: 1.2;
                font-size: 12px;

                @include media-max(640px) {
                    position: static;
                    padding-top: rem(100px);

                    img {
                        width: rem(90px);
                    }
                }
            }
        }
    }
    .peak {
        @include media-max(640px) {
            display: grid;
            min-height: 1px;
            overflow-y: hidden;
        }

        .wrapper {
            position: absolute;
            z-index: 9;
            top: rem(100px);
            left: 0;
            right: 0;
            margin: auto;

            @include media-max(640px) {
                position: static;
                order: -1;
            }
        }
        .content {
            max-width: 42%;

            @include media-max(960px) {
                .t120 {
                    font-size:rem(60px);
                }
            }
            @include media-max(640px) {
                max-width: 100%;
            }
        }
        .background {
            position: relative;

            @include media-max(640px) {
                scale: 1.35;
                transform-origin: right top;
                margin-top: rem(50px);
            }
            @include media-max(450px) {
                scale: 1;
            }

            img {
                width: 100%;
            }
            .front {
                position: absolute;
                inset:0;
                z-index: 9;
                pointer-events: none;
            }
        }
        .data {
            position: absolute;
            top: rem(180px);
            right: 0;
            z-index: 7;

            img, svg {
                width: rem(1170px);
            }

            @include media-max(1200px) {
                top:rem(80px);

                img, svg {
                    width: rem(800px) !important;
                }
            }
            @include media-max(960px) {
                top:rem(50px);

                img, svg {
                    width: rem(500px) !important;
                }
            }
            @include media-max(640px) {
                top:rem(15px);
            }
            @include media-max(450px) {
                top:rem(-20px);
            }
        }
    }
    .theway {
        position: relative;
        // overflow-y: hidden;

        .wrapper {
            max-width: 90%;
        }

        .stream {
            position: absolute;
            left: rem(448px);
            width: rem(134px);
            height: rem(1550px);
            top: rem(420px);
            background: rgb(255,255,255);
            background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%);
            background: linear-gradient(180deg, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
            animation-duration: 1500ms !important;
            z-index: 8;

            @include media-max(1200px) {
                left: rem(270px);
                width: rem(78px);
                height: rem(1350px);
                top: rem(200px);
            }
            @include media-max(960px) {
                left: rem(171px);
                width: rem(50px);
                height: rem(1350px);
                top: rem(180px);
            }
            @include media-max(640px) {
                display: none;
            }
        }
        .stream_dot_follow_container {
            height: 50%;
            position: absolute;
            top:0;
            left:0;
        }
        .stream_dot_container {
            position: -webkit-sticky;
            position: sticky;
            top:0;
            z-index: 99;
        }
        .dot {
            position: relative;
            top: rem(410px);
            left: rem(495px);
            width: rem(40px);
            height: rem(40px);
            background: $green;
            border-radius: 1000px;
            z-index: 9;

            @include media-max(1200px) {
                top: rem(230px);
                left: rem(290px);
            }
            @include media-max(960px) {
                top: rem(148px);
                left: rem(187px);
                width: rem(20px);
                height: rem(20px);
            }
            @include media-max(640px) {
                display: none;
            }
        }
        .sect1 {
            background-position: left top;
            background-size: 100%;
            background-repeat: no-repeat;
            min-height: rem(820px);

            @include media-max(1200px) {
                min-height: rem(550px);
            }
            @include media-max(640px) {
                min-height: 1px;
                background-image: none !important;
            }

            .content {
                max-width: 40%;
                margin-left: auto;
                padding-block: rem(250px);

                @include media-max(1200px) {
                    padding-block: rem(40px);

                    .t120 {
                        font-size: rem(60px);
                    }
                }
                @include media-max(640px) {
                    max-width: 100%;
                    padding-top: rem(100px);
                }
    
                .t18 {
                    line-height: 1.5;
                }
            }
        }
        .sect2 {
            background-position: left center;
            background-size: contain;
            background-repeat: no-repeat;
            min-height: rem(1920px);

            @include media-max(640px) {
                min-height: rem(880px);
                background-position: left bottom;
            }

            .list {
                margin-left: rem(490px);
                
                @include media-max(1200px) {
                    margin-left: rem(290px);
                }
                @include media-max(960px) {
                    margin-left: rem(180px);
                }
                @include media-max(640px) {
                    margin-left: 0;
                }
                div {
                    padding-block:rem(40px);
                    padding-inline: rem(90px);
                    border-bottom: 1px dashed $grey80;

                    @include media-max(640px) {
                        padding-block: rem(20px);
                        padding-inline: rem(40px);
                    }
                }
            }
        }
    }
    .work {
        padding-top: rem(300px);
        padding-bottom: rem(350px);

        @include media-max(1200px) {
            padding-top: rem(150px);
            padding-bottom: rem(150px);
        }
        @include media-max(960px) {
            padding-top: rem(100px);
            padding-bottom: rem(100px);
        }
        @include media-max(640px) {
            padding-top: 0;
            padding-bottom: rem(50px);
        }

        .content {
            display: grid;
            grid-template-columns: repeat(2, minmax(0,1fr));

            @include media-max(960px) {
                grid-template-columns: 1fr;
                gap: rem(30px);
            }

            .title__container {
                height: 100%;
                position: relative;

                .title  {
                    position: absolute;
                    top: 70%;
                    width: 100vw;

                    @include media-max(960px) {
                        position: static;
                        max-width: 90%;
                        margin-inline: auto;
                    }

                    &.t120 {
                        @include media-max(1200px) {
                            font-size: rem(71.5px) !important;
                        }
                    }
                 
                    svg {
                        position: absolute;
                        top: 84%;
                        left: 6px;

                        @include media-max(1200px) {
                            max-width: 99%;
                        }
                        @include media-max(960px) {
                            max-width: 100%;
                            scale: 1.2;
                            position: static;
                        }
                    }
                }
            }
            .desc {
                padding-inline: rem(90px);

                @include media-max(960px) {
                    padding-inline: 0;
                    max-width: 90%;
                    margin-inline: auto;
                }
            }
        }
    }
    .character__cards {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center rem(30px);
        margin-top:rem(300px);
        min-height: 250vh;
        position: relative;

        @include media-max(960px) {
            min-height: 1px;
            background-position: right top;
        }

        .cards__container {
            display: grid;

            .character__card {
                position: relative;
                isolation: isolate;

                @include media-max(960px) {
                    margin-bottom: rem(60px);
                }

                &:nth-child(10) {
                    z-index: 1;
                }
                &:nth-child(9) {
                    z-index: 2;
                }
                &:nth-child(8) {
                    z-index: 3;
                }
                &:nth-child(7) {
                    z-index: 4;
                }
                &:nth-child(6) {
                    z-index: 5;
                }
                &:nth-child(5) {
                    z-index: 6;
                }
                &:nth-child(4) {
                    z-index: 7;
                }
                &:nth-child(3) {
                    z-index: 8;
                }
                &:nth-child(2) {
                    z-index: 9;
                }
                &:nth-child(1) {
                    z-index: 10;
                }

                ::selection {
                    background-color: transparent;
                    color: #fff;
                }
                
                &:nth-of-type(even) {
                    max-width: 50%;

                    .offset {
                        margin-left: 15vw;
                    }
                }

                .trigger__title {
                    cursor: pointer;
                    opacity:0.4;
                    padding-bottom: 20px;
                    transition: $t-all;
                    background-size: cover;

                    @include media-max(640px) {
                        &.t90 {
                            font-size: rem(40px);
                        }
                    }

                    &:hover {
                        opacity:1;
                        background: linear-gradient(153.01deg, #88FC6C 2.53%, #00C2FF 95.86%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                    }
                }

                &.active {
                    .overlay__bg {
                        width:100vw;
                    }
                    .overlay {
                        left: 0%;
                        opacity:1;
                    }
                }

                .offset {
                    margin-left: auto;
                    margin-right: 15vw;
                    width: 30vw;
                    
                    @include media-max(960px) {
                        margin-left: 15vw;
                        margin-right: auto;
                        width: 70vw;
                    }
                }

                .overlay__bg {
                    background-color: rgba(0,0,0,0.7);
                    position: absolute;
                    top:50%;
                    left:0;
                    translate: 0 -50%;
                    width: 0vw;
                    min-height: 400px;
                    display: flex;
                    align-items: center;
                    align-content: center;
                    pointer-events: none;
                    transition: $t-all-slow;
                }
                .overlay {
                    position: absolute;
                    top:60%;
                    left:-100vw;
                    translate: 0 -60%;
                    width: 100vw;
                    height: 120%;
                    display: flex;
                    align-items: center;
                    align-content: center;
                    opacity: 0;
                    transition: $t-all-slow;

                    .offset {
                        position: relative;

                        .close {
                            position: absolute;
                            width: rem(40px);
                            top:rem(-20px);
                            right:0;
                            cursor: pointer;
                        }
                    }

                    .title {
                        margin-bottom: rem(20px);
                    }
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
    .certifications {
        padding-block:rem(200px);

        .certifications__grid {
            display: grid;
            grid-template-columns: repeat(2, minmax(0,1fr));

            @include media-max(960px) {
                grid-template-columns: 1fr;
            }
        }
        .content {
            display: grid;
            gap: rem(40px);
            align-content: center;
            margin-right: rem(60px);

            .icons {
                display: flex;
                align-items: center;
                align-content: center;
                gap:30px;
                flex-wrap: wrap;
            }
        }
        .mary {
            position: relative;

            img, svg {
                width: 100%;
            }
            .mary__image {
                scale:0.85;
                z-index: -1;
            }
            .text__effect {
                position: absolute;
                inset:0;
                margin: auto;
                z-index: 9;
            }
            .pulse__container {
                display: block;
                width: rem(120px);
                height: rem(120px);
                position: absolute;
                inset:0;
                margin: auto;
                translate: 0 rem(40px);
                mix-blend-mode: difference;
            }
            .pulse {
                display: block;
                width: rem(120px);
                height: rem(120px);
                background-color: $green;
                position: absolute;
                inset:0;
                margin: auto;
                border-radius: 1000px;
                z-index: 11;
                mix-blend-mode: difference;
                -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
                animation: heartbeat 1.5s ease-in-out infinite both;
                
                @include media-max(960px) {
                    width: rem(80px);
                    height: rem(80px);
                }
            }
        }
    }
    .langs__card {
        padding-bottom: rem(250px);

        .lang__card {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 40px;
            padding: rem(170px 120px);
            margin-bottom: rem(40px);

            @include media-max(960px) {
                padding: rem(85px 60px);
            }
            @include media-max(640px) {
                padding: rem(85px 60px);
                border: 1px solid #4C5D64;
                aspect-ratio: 4/5;
                background-size: contain;
                background-position: center bottom;
                background-image: url('../../assets/img/development/lang_card_mobile.jpg') !important;
            }
            @include media-max(450px) {
                aspect-ratio: 1/1.8;
            }

            .card__inner {
                max-width: 55%;

                @include media-max(640px) {
                    max-width: 100%;
                }
            }
        }
        .border {
            border-radius: 40px;
            border: 1px solid #4C5D64;
        }
        .flex {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-around;
            padding-top:rem(75px);
            padding-bottom:rem(75px);
    
            @include media-max(960px) {
                img {
                    height:rem(45px);
                }
            }
            @include media-max(640px) {
                padding: rem(25px);
                flex-wrap: wrap;
                gap:rem(25px);
    
                img {
                    height:rem(85px);
                }
            }
        }
    }
}