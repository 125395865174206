// ------------------------------------------------------------
// @include font-face($name, $path, $weight: null, $style: null, $exts: woff2 woff);
// Use only woff2, woff formats, both are required
// Example: @include font-face('Font Name', $font-dir+'font-file-name', 400);
// @include font-face('Open Sans', $open-sans-dir+'OpenSans-Regular', 400);
// ------------------------------------------------------------
// Default fonts directory
$fonts: '../assets/fonts/';

@font-face {
    font-family: 'DM Sans';
    src: url($fonts + 'DMSans-MediumItalic.eot');
    src: url($fonts + 'DMSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMSans-MediumItalic.woff2') format('woff2'),
        url($fonts + 'DMSans-MediumItalic.woff') format('woff'),
        url($fonts + 'DMSans-MediumItalic.ttf') format('truetype'),
        url($fonts + 'DMSans-MediumItalic.svg#DMSans-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url($fonts + 'DMSans-Medium.eot');
    src: url($fonts + 'DMSans-Medium.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMSans-Medium.woff2') format('woff2'),
        url($fonts + 'DMSans-Medium.woff') format('woff'),
        url($fonts + 'DMSans-Medium.ttf') format('truetype'),
        url($fonts + 'DMSans-Medium.svg#DMSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Mono';
    src: url($fonts + 'DMMono-MediumItalic.eot');
    src: url($fonts + 'DMMono-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMMono-MediumItalic.woff2') format('woff2'),
        url($fonts + 'DMMono-MediumItalic.woff') format('woff'),
        url($fonts + 'DMMono-MediumItalic.ttf') format('truetype'),
        url($fonts + 'DMMono-MediumItalic.svg#DMMono-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url($fonts + 'DMSans-Regular.eot');
    src: url($fonts + 'DMSans-Regular.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMSans-Regular.woff2') format('woff2'),
        url($fonts + 'DMSans-Regular.woff') format('woff'),
        url($fonts + 'DMSans-Regular.ttf') format('truetype'),
        url($fonts + 'DMSans-Regular.svg#DMSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Mono';
    src: url($fonts + 'DMMono-LightItalic.eot');
    src: url($fonts + 'DMMono-LightItalic.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMMono-LightItalic.woff2') format('woff2'),
        url($fonts + 'DMMono-LightItalic.woff') format('woff'),
        url($fonts + 'DMMono-LightItalic.ttf') format('truetype'),
        url($fonts + 'DMMono-LightItalic.svg#DMMono-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url($fonts + 'DMSans-BoldItalic.eot');
    src: url($fonts + 'DMSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMSans-BoldItalic.woff2') format('woff2'),
        url($fonts + 'DMSans-BoldItalic.woff') format('woff'),
        url($fonts + 'DMSans-BoldItalic.ttf') format('truetype'),
        url($fonts + 'DMSans-BoldItalic.svg#DMSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url($fonts + 'DMSans-Italic.eot');
    src: url($fonts + 'DMSans-Italic.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMSans-Italic.woff2') format('woff2'),
        url($fonts + 'DMSans-Italic.woff') format('woff'),
        url($fonts + 'DMSans-Italic.ttf') format('truetype'),
        url($fonts + 'DMSans-Italic.svg#DMSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Mono';
    src: url($fonts + 'DMMono-Light.eot');
    src: url($fonts + 'DMMono-Light.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMMono-Light.woff2') format('woff2'),
        url($fonts + 'DMMono-Light.woff') format('woff'),
        url($fonts + 'DMMono-Light.ttf') format('truetype'),
        url($fonts + 'DMMono-Light.svg#DMMono-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url($fonts + 'DMSans-Bold.eot');
    src: url($fonts + 'DMSans-Bold.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMSans-Bold.woff2') format('woff2'),
        url($fonts + 'DMSans-Bold.woff') format('woff'),
        url($fonts + 'DMSans-Bold.ttf') format('truetype'),
        url($fonts + 'DMSans-Bold.svg#DMSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Mono';
    src: url($fonts + 'DMMono-Medium.eot');
    src: url($fonts + 'DMMono-Medium.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMMono-Medium.woff2') format('woff2'),
        url($fonts + 'DMMono-Medium.woff') format('woff'),
        url($fonts + 'DMMono-Medium.ttf') format('truetype'),
        url($fonts + 'DMMono-Medium.svg#DMMono-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Mono';
    src: url($fonts + 'DMMono-Italic.eot');
    src: url($fonts + 'DMMono-Italic.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMMono-Italic.woff2') format('woff2'),
        url($fonts + 'DMMono-Italic.woff') format('woff'),
        url($fonts + 'DMMono-Italic.ttf') format('truetype'),
        url($fonts + 'DMMono-Italic.svg#DMMono-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Mono';
    src: url($fonts + 'DMMono-Regular.eot');
    src: url($fonts + 'DMMono-Regular.eot?#iefix') format('embedded-opentype'),
        url($fonts + 'DMMono-Regular.woff2') format('woff2'),
        url($fonts + 'DMMono-Regular.woff') format('woff'),
        url($fonts + 'DMMono-Regular.ttf') format('truetype'),
        url($fonts + 'DMMono-Regular.svg#DMMono-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

