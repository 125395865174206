footer {
    padding-top:rem(170px);
    padding-bottom:rem(70px);
    position: relative;
    background-color: #000;
    
    @include media-max(960px) {
        padding-top:rem(50px);
    }

    .wrapper {
        max-width:1280px;
        margin-right: auto;
        margin-left: auto;

        @include media-max(1440px) {
            max-width:90%;
        }
    }

    .backtotop {
        position: absolute;
        right:0;
        bottom:0;

        .btt__image {
            position: relative;

            img, svg {
                width:rem(214px);
                
                @include media-max(1200px) {
                    width:rem(140px);
                }
                @include media-max(960px) {
                    width:rem(100px);
                }
            }

            span {
                position: absolute;
                top:35%;
                left:27%;
                
                @include media-max(1200px) {
                    left:20%;
                }
                @include media-max(960px) {
                    left:12%;
                }
            }
        }

        
        @include media-max(640px) {
            position: static;

            .btt__image {
                text-align:center;
                
                img, svg {
                    display: none;
                }
                span {
                    display: block;
                    margin-top:rem(30px);
                    position: static;
                }

            }
        }
    }
    a {
        span {
            transition: $t-all;
        }
    }
    a {
        &:hover {
            span {
                color:#fff;
            }
            svg {
                path {
                    color:$green;
                    fill:$green;
                }
            }
        }
    }

    .footer__top {
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr 1fr;
        gap:rem(60px);

        @include media-max(640px) {
            grid-template-columns:1fr;
        }

        a {
            &:hover {
                color:$green;
            }
        }
    }
    .menu {
        .title {
            margin-bottom:rem(30px);
        }
    }
    .list {
        a {
            display: block;
        }
    }
    .footer__logo {
        margin-bottom:rem(60px);
    }
    .button__container {
        margin-top: rem(40px);
    }
    .copyright {
        margin-top: rem(50px);
        display: flex;
        gap:rem(25px);

        a {
            &:hover {
                color: $green;

                svg, path {
                    fill: $green;
                }
            }
        }

        span {
            margin-right: rem(20px);
        }
    }
}
