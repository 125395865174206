//
// Component: Thumbnav
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

$thumbnav-item-background:                       rgba($global-background, 0.4) !default;
$thumbnav-item-hover-background:                 transparent !default;
$thumbnav-item-active-background:                transparent !default;


// Component
// ========================================================================

// @mixin hook-thumbnav(){}








// Miscellaneous
// ========================================================================

// @mixin hook-thumbnav-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-thumbnav-item(){}
// @mixin hook-inverse-thumbnav-item-hover(){}
// @mixin hook-inverse-thumbnav-item-active(){}