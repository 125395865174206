.bg-transition-fade {
    background-position-x: center !important;
    transition: background-position-x 3000ms ease-in-out;
}
.cloudforming__page {
    .section {
        .wrapper {
            max-width: 1280px;
            margin-right: auto;
            margin-left: auto;

            @include media-min(1921px) {
                max-width: rem(1800px);
            }

            @include media-max(1300px) {
                max-width: 90%;
            }
        }
    }
    .gradient__blue {
        background: linear-gradient(94.07deg, #00A4EF 14.4%, #6005FE 57.89%, #D93FFF 107.05%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
    .gradient__green {
        background: linear-gradient(153.01deg, #88FC6C 2.53%, #00C2FF 95.86%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
    .gradient__orange {
        background: linear-gradient(94.07deg, #FAFD5E 14.4%, #FFB822 57.89%, #FFF62A 107.05%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
    .gradient__magenta {
        background: linear-gradient(94.07deg, #FF3D57 14.4%, #DD42FF 57.89%, #FF3D57 107.05%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
    .hero {
        min-height: 100vh;
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;

        @include media-max(640px) {
            min-height: unset;
        }

        &.padding_bottom_200 {
            @include media-max(640px) {
                padding-bottom: rem(50px);
            }
        }

        .hero__title {
            background-size: contain;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            mix-blend-mode: luminosity;
            filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.4));
        }
        .hero__content {
            &.padding_top_900 {
                @include media-max(640px) {
                    padding-top: rem(400px);
                }
            }
        }
        .hero__text {
            max-width: 60%;

            @include media-max(640px) {
                max-width: 100%;
            }
        }
    }
    .straight {
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;

        @include media-max(960px) {
            background-position: 80% top;
        }

        @include media-max(640px) {
            min-height: unset !important;
        }

        .section__text  {
            max-width: 60%;
        }

        .padding_block_700 {
            @include media-max(640px) {
                padding-block: rem(200px);
            }
        }
    }
    .incloud {
        background-size: contain;
        background-position: center top;
        background-repeat: no-repeat;
        overflow: visible;
        z-index: 9;

        @include media-max(960px) {
            padding-top: rem(100px);
        }
        @include media-max(450px) {
            min-height: unset !important;
        }

        * {
            text-align: right;
        }

        .section__text {
            max-width: 60%;
            margin-left: auto;
        }

        .orb {
            position: relative;

            & > * {
                position: absolute;
                inset:0;
                margin: auto;
                width: 54vw;
            }

            @include media-max(1200px) {
                padding-bottom: rem(520px) !important;

                .orbs__higlights, .orbs__dots {
                    translate: 0 0;
                }
            }
            @include media-max(960px) {
                padding-bottom: rem(67px) !important;

                .orbs__higlights, .orbs__dots {
                    translate: 0 rem(80px);
                }
            }
            @include media-max(640px) {
                padding-top: rem(410px) !important;
                padding-bottom: rem(100px) !important;

                .orbs__higlights, .orbs__dots {
                    translate: 0 rem(20px);
                }
            }
            @include media-max(450px) {
                padding-top: rem(220px) !important;
                padding-bottom: rem(50px) !important;

                .orbs__higlights, .orbs__dots {
                    translate: 0 rem(-40px);
                }
            }
        }

        .connector__paths {
            position: absolute;
            inset: 0;
            top: auto;
            margin-inline: auto;
            translate: 0 rem(120px);
            width: 52vw;

            img, svg {
                width: 100%;
            }
        }

        .separator {
            @include media-max(1200px) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        .small_sep {
            @include media-max(1200px) {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
    .services {
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: luminosity;

        .services__row {
            margin-block: rem(20px);
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            align-items: center;
            align-content: center;
            gap:rem(50px);
            text-align:right;
            min-height: 400px;
            position: relative;
            
            @include media-max(960px) {
                display: grid;
                gap:rem(15px);
                grid-template-columns: 1fr;
                min-height: unset !important;

                & * {
                    text-align: center !important;
                }

                .content {
                    order:3 !important;
                }
                div:nth-child(2) {
                    order:2 !important;
                }
                div:nth-child(3) {
                    order:1 !important;
                }
            }

            &:after {
                content:'';
                display: block;
                width: 1px;
                height:rem(150px);
                border-right: 1px dotted #fff;
                position: absolute;
                inset:0;
                top:100%;
                margin-inline: auto;
                translate: 0 -50%;

                @include media-max(960px) {
                    position: static;
                    translate: 0 0;
                    order:4;
                }
            }
            &:last-of-type {
                &:after {
                    display: none !important;
                }
            }

            .title {
                margin-bottom: rem(15px);
            }

            &:nth-child(even) {
                text-align:left;

                .content {
                    order:3;
                }
                div:nth-child(2) {
                    order:2;
                }
                div:nth-child(3) {
                    order:1;
                }
            }
        }

        .connector {
            margin-bottom: rem(-70px);
            position: relative;
            z-index: 13;

            @include media-max(960px) {
                margin-bottom: rem(30px);
            }

            img, svg {
                width: 54vw;
                display: block;
                margin-inline: auto;
            }
        }
    }
    .leap {
        background-size: 100%;
        background-position: center top;
        background-repeat: no-repeat;

        .section__text {
            max-width: 50%;

            @include media-max(640px) {
                max-width: 100%;
            }
        }

        @include media-max(1200px) {
            .padding_top_400 {
                padding-top: rem(200px);
            }
            .padding_bottom_700 {
                padding-bottom: rem(250px);
            }
        }
        @include media-max(960px) {
            background-position: center bottom;
        }
    }
    .safe {
        background-size: 100%;
        background-position: center top;
        background-position-x: -3000px;
        background-repeat: no-repeat;

        @include media-max(960px) {
            min-height: unset !important;
        }

        .padding_top_600 {
            @include media-max(960px) {
                padding-top: rem(200px);
            }
            @include media-max(640px) {
                padding-top: rem(360px);

                .title {
                    text-align: right;
                }
            }
            @include media-max(450px) {
                .title {
                    text-align: left;
                }
            }
        }
        .padding_bottom_800 {
            @include media-max(1200px) {
                padding-bottom: rem(400px);
            }
            @include media-max(960px) {
                padding-bottom: rem(200px);
            }
            @include media-max(640px) {
                padding-bottom: rem(0px);
            }
        }
        .t150 {
            @include media-max(960px) {
                font-size: rem(60px) !important;
            }
        }
    }
    .discover {
        background-size: 100%;
        background-position: center top;
        background-repeat: no-repeat;

        @include media-max(640px) {
            min-height: unset !important;
        }

        .section__text {
            max-width: 50%;
        }

        @include media-max(1200px) {
            margin-top: rem(100px);

            .padding_top_300 {
                padding-top: 0;
            }
            .padding_bottom_800 {
                padding-bottom: rem(400px);
            }
        }
        @include media-max(960px) {
            .padding_bottom_800 {
                padding-bottom: rem(100px);
            }
        }
        @include media-max(640px) {
            .padding_bottom_800 {
                padding-bottom: rem(50px);
            }
        }
        @include media-max(450px) {
            .padding_bottom_800 {
                padding-bottom: rem(0px);
            }
        }
    }

    .t150 {
        @include media-max(960px) {
            font-size:rem(95px) !important;
        }
        @include media-max(640px) {
            font-size:rem(80px) !important;
        }
        @include media-max(450px) {
            font-size:rem(50px) !important;
        }
    }
}