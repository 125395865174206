$spacers: (
    10: rem(10px),
    20: rem(20px),
    30: rem(30px),
    40: rem(40px),
    50: rem(50px),
    60: rem(60px),
    80: rem(80px),
    100: rem(100px),
    120: rem(120px),
    145: rem(145px),
    200: rem(200px),
    230: rem(230px),
    250: rem(250px),
    300: rem(300px),
    400: rem(400px),
    500: rem(500px),
    600: rem(600px),
    700: rem(700px),
    800: rem(800px),
    900: rem(900px),
    n200: rem(-200px),
    n300: rem(-300px),
    n600: rem(-600px),
    n900: rem(-900px),
    n1000: rem(-1000px),
);
@mixin generate-spacers($attribute: '') {
    @each $name, $val in $spacers {
        &_top_#{$name} {
            #{$attribute}-top: $val;
        }
        &_bottom_#{$name} {
            #{$attribute}-bottom: $val;
        }
        &_right_#{$name} {
            #{$attribute}-right: $val;
        }
        &_left_#{$name} {
            #{$attribute}-left: $val;
        }
        &_block_#{$name} {
            #{$attribute}-block: $val;
        }
        &_inline_#{$name} {
            #{$attribute}-inline: $val;
        }
    }
}
@mixin generate-1200-spacers($attribute: '') {
    @each $name, $val in $spacers {
        &_top_#{$name}_1200 {
            @include media-max(1200px) {
                #{$attribute}-top: $val;
            }
        }
        &_bottom_#{$name}_1200 {
            @include media-max(1200px) {
                #{$attribute}-bottom: $val;
            }
        }
        &_right_#{$name}_1200 {
            @include media-max(1200px) {
                #{$attribute}-right: $val;
            } 
        }
        &_left_#{$name}_1200 {
            @include media-max(1200px) {
                #{$attribute}-left: $val;
            }
        }
        &_block_#{$name}_1200 {
            @include media-max(1200px) {
                #{$attribute}-block: $val;
            }
        }
        &_inline_#{$name}_1200 {
            @include media-max(1200px) {
                #{$attribute}-inline: $val;
            }
        }
    }
}
.padding {
    @include generate-spacers(padding);
    @include generate-1200-spacers(padding);
}
.margin {
    @include generate-spacers(margin);
    @include generate-1200-spacers(margin);
}
.padding_0 {
    padding: 0 !important;
}