//
// Component: Totop
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================



// @mixin hook-totop-hover(){}

// @mixin hook-totop-active(){}


// Miscellaneous
// ========================================================================

// @mixin hook-icon-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-totop(){}
// @mixin hook-inverse-totop-hover(){}
// @mixin hook-inverse-totop-active(){}