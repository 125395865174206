.t180 {
    font-size:rem(180px);
    line-height: 1.1;
    
    @include media-max(1200px) {
        font-size:rem(110px);
    }
}
.t150 {
    font-size:rem(150px);
    line-height: 1.1;
    
    @include media-max(1200px) {
        font-size:rem(120px);
    }
}
.t120 {
    font-size:rem(120px);
    line-height: 1.1;
    
    @include media-max(1200px) {
        font-size:rem(90px);
    }
}
.t100 {
    font-size:rem(100px);
    line-height: 1.1;
    
    @include media-max(1200px) {
        font-size:rem(70px);
    }
    @include media-max(960px) {
        font-size:rem(60px);
    }
    @include media-max(640px) {
        font-size:rem(40px);
    }
}
.t90 {
    font-size:rem(90px);
    line-height: 1.1;
    
    @include media-max(1200px) {
        font-size:rem(60px);
    }
}
.t80 {
    font-size:rem(80px);
    line-height: 1.2;

    @include media-max(1200px) {
        font-size:rem(50px);
    }
}
.t60 {
    font-size:rem(60px);
    line-height: 1.2;

    @include media-max(1200px) {
        font-size:rem(38px);
    }
}
.t50 {
    font-size:rem(50px);
    line-height: 1.2;

    @include media-max(1200px) {
        font-size:rem(35px);
    }
}
.t40 {
    font-size:rem(40px);
    line-height: 1.1;

    @include media-max(1200px) {
        font-size:rem(30px);
    }
}
.t36 {
    font-size:rem(36px);
    line-height: 1.1;

    @include media-max(1200px) {
        font-size:rem(24px);
    }
}
.t32 {
    font-size:rem(32px);
    line-height: 1.1;

    @include media-max(1200px) {
        font-size:rem(26px);
    }
    @include media-max(960px) {
        font-size:rem(21px);
    }
}
.t28 {
    font-size:rem(28px);
    line-height: 1.1;

    @include media-max(1200px) {
        font-size:rem(24px);
    }
    @include media-max(960px) {
        font-size:rem(20px);
    }
}
.t24 {
    font-size:rem(24px);
    line-height: 1.1;

    @include media-max(1200px) {
        font-size:rem(22px);
    }
    @include media-max(960px) {
        font-size:rem(19px);
    }
}
.t18 {
    font-size:rem(18px);
    line-height: 1.1;

    @include media-max(1200px) {
        font-size:rem(18px);
    }
    @include media-max(960px) {
        font-size:rem(17px);
    }
}
.t14 {
    font-size:rem(14px);
    line-height: 1.1;

    @include media-max(1200px) {
        font-size:rem(12px);
    }
    @include media-max(960px) {
        font-size:rem(11px);
    }
}
.t10 {
    font-size:rem(10px);
    line-height: 1.1;

    @include media-max(1200px) {
        font-size:rem(9px);
    }
    @include media-max(960px) {
        font-size:rem(8px);
    }
}
.t8 {
    font-size:8px;
    line-height: 1.1;
}
.bold {
    font-weight:700;
}
.medium {
    font-weight:500;
}
.center {
    text-align: center;
}
.right {
    text-align: right;
}
.uppercase {
    text-transform: uppercase;
}