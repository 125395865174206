//
// Component: Close
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================



// @mixin hook-close-hover(){}


// Miscellaneous
// ========================================================================

// @mixin hook-close-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-close(){}
// @mixin hook-inverse-close-hover(){}