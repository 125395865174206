//
// Component: Slidenav
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================



// @mixin hook-slidenav-hover(){}

// @mixin hook-slidenav-active(){}


// Icon modifier
// ========================================================================

// @mixin hook-slidenav-previous(){}

// @mixin hook-slidenav-next(){}


// Size modifier
// ========================================================================

// @mixin hook-slidenav-large(){}


// Container
// ========================================================================

// @mixin hook-slidenav-container(){}


// Miscellaneous
// ========================================================================

// @mixin hook-icon-misc(){}


// Inverse
// ========================================================================

// @mixin hook-inverse-slidenav(){}
// @mixin hook-inverse-slidenav-hover(){}
// @mixin hook-inverse-slidenav-active(){}