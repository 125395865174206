//
// Component: Notification
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

// @mixin hook-notification(){}


// Message
// ========================================================================

// @mixin hook-notification-message(){}


// Close
// ========================================================================

// @mixin hook-notification-close(){}


// Style modifiers
// ========================================================================

// @mixin hook-notification-message-primary(){}

// @mixin hook-notification-message-success(){}

// @mixin hook-notification-message-warning(){}

// @mixin hook-notification-message-danger(){}


// Miscellaneous
// ========================================================================

// @mixin hook-notification-misc(){}