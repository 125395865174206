// ------------------------------
// Variables
// ------------------------------

//Font
$font-family: 'DM Sans', Arial, sans-serif;

//Breakpoints
$sm: 640px;
$md: 960px;
$lg: 1200px;
$xl: 1600px;
//Colors
$link: #88FC6C;
$green:#88FC6C;
$blue:#00A4EF;
$skyblue: #00FFF0;
$grey80:#4C5D64;
$grey60:#68767C;
$grey40:#9FA4A6;
$grey20:#E0E2E2;
$dimmedblack: #151515;

$dred: #FFB822;
$dblue:#5E01FF;
$dgreen:#88FC6C;

.grey80 {
    color:$grey80;
}
.grey60 {
    color:$grey60;
}
.grey40 {
    color:$grey40;
}
.grey20 {
    color:$grey20;
}
.green {
    color:$green;
}
.blue {
    color:$blue;
}
.skyblue {
    color: $skyblue;
}
.black__bg {
    background-color: #000;
}
//Transitions
$t-color: color .3s ease-in-out;
$t-bottom: bottom .3s ease-in-out;
$t-bc: background-color .3s ease-in-out;
$t-bd-b: border-bottom .3s ease-in-out;
$t-bd-col: border-color .3s ease-in-out;
$t-left: left .3s ease-in-out;
$t-margin: margin .3s ease-in-out;
$t-opacity: opacity .3s ease-in-out;
$t-top: top .3s ease-in-out;
$t-trans: transform .3s ease-in-out;
$t-width: width .3s ease-in-out;
$t-all: all .3s ease-in-out;
$t-all-slow: all 1s ease-in-out;
$t-all-mid-slow: all 1.5s ease-in-out;
$t-all-extra-slow: all 2s ease-in-out;
$t-all-f: all .1s ease-in-out;

