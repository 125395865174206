$headerOffset: 150px;
$headerOffset1200: 75px;
header {
    position: absolute;
    top:rem($headerOffset);
    right:0;
    left:0;
    width:100%;
    z-index: 99;
    
    @include media-max(1200px) {
        top: rem($headerOffset1200);
    }
    
    &.header__page {
        position: static;
        margin-top:rem($headerOffset);
        margin-bottom:rem($headerOffset1200);
        
        @include media-max(1200px) {
            margin-top: rem($headerOffset1200);
            margin-bottom:rem($headerOffset1200);
        }
    }
}
.header__wrapper {
    max-width:1280px;
    margin-right: auto;
    margin-left: auto;
    display:flex;
    align-items: center;
    justify-content: space-between;
    
    @include media-max(1440px) {
        max-width:90%;
    }
    
    nav {
        display: flex;
        align-items: center;
        gap:rem(30px);
        
        @include media-max(960px) {
            gap:rem(15px);
        }
        
        ul {
            align-items: center;
            display: flex;
            gap:rem(50px);

            @include media-max(960px) {
                gap:rem(15px);
            }
        }
    }
}
a {
    &:hover {
        color:#fff;
    }
}
.menu__button {
    display:flex;
    width:rem(64px);
    height:rem(64px);
    place-content: center;
    place-items: center;
    position: relative;
    border-radius: 1000px;
    color:transparent;
    
    &:before {
        content:'';
        position: absolute;
        inset:0;
        margin: auto;
        width:rem(18px);
        height:rem(18px);
        background-color: $green;
        border-radius: 1000px;
        transition: $t-all;
        border:1px solid $green;
    }
    &:hover {
        color:$green;

        &:before {
            background-color: transparent;
            width:rem(64px);
            height:rem(64px);
        }
    }

    &.close__button {
        color:#000;

        &:before {
            background-color: $green;
            width:rem(64px);
            height:rem(64px);
            z-index: -1;
        }
    }
}
.menu__container {
    position: absolute;
    inset:0;
    z-index:109;
    background: #000;
    transition: $t-all-slow;
    isolation: isolate;
    overflow: auto;

    clip-path: circle(0% at calc(50vw + 605px) rem(182px));
    -webkit-clip-path: circle(0% at calc(50vw + 605px) rem(182px));

    @include media-max(1440px) {
        clip-path: circle(0% at 93vw rem(182px));
        -webkit-clip-path: circle(0% at 93vw rem(182px));
    }

    &.active {
        clip-path: circle(120% at calc(50vw + 605px) rem(182px));
        -webkit-clip-path: circle(120% at calc(50vw + 605px) rem(182px));
        
        @include media-max(1440px) {
            clip-path: circle(120% at 93vw rem(182px));
            -webkit-clip-path: circle(120% at 93vw rem(182px));
        }
        .overlay {
            opacity:0;
        }
    }

    .overlay {
        pointer-events: none;
        position: absolute;
        inset:0;
        width:100%;
        height:100%;
        background: $green;
        z-index: 99;
        transition: $t-all-slow;
    }

    .header__wrapper {
        margin-top:rem($headerOffset);

        @include media-max(1200px) {
            margin-top: rem($headerOffset1200);
        }
    }

    .wrapper {
        max-width:1280px;
        margin-right: auto;
        margin-left: auto;

        @include media-max(1440px) {
            max-width:90%;
        }
    }
    .menu__content {
        margin-top:rem(60px);
        margin-bottom:rem(80px);
        
        @include media-max(960px) {
            margin-top:rem(30px);
            margin-bottom:rem(30px);
        }
    }
    .menu__grid {
        display: grid;
        grid-template-columns: 2fr 1fr 3fr;
        gap:rem(60px);

        @include media-max(640px) {
            grid-template-columns: 1fr 1fr;
            gap:rem(20px);
        }
        @include media-max(460px) {
            grid-template-columns: 1fr;
        }
    }
    .list__title {
        margin-bottom:rem(40px);

        @include media-max(640px) {
            margin-bottom:rem(10px);
        }
    }
    .list__items {
        
        a {
            display: block;
            margin-bottom:rem(25px);
            
            @include media-max(640px) {
                margin-bottom:rem(10px);
            }

            &:hover {
                color:$green;
            }
        }
    }
    .menu__cards {
        margin-top:rem(30px);

        @include media-max(640px) {
            grid-column: 1/-1;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap:rem(20px);
            margin-top:0;
        }
        @include media-max(460px) {
            grid-template-columns: 1fr;
        }

        .menu__card {
            position: relative;
            margin-top:rem(25px);
            
            @include media-max(640px) {
                margin-top:0;
            }

            &:hover {
                &:after {
                    opacity:.1;
                }
            }

            &:after {
                content:'';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                inset: 0;
                background: $green;
                opacity:0;
                pointer-events: none;
                transition: $t-all;
            }

            img {
                width:100%;
            }
            
            span {
                text-transform: uppercase;
                position: absolute;
                bottom:rem(15px);
                left:rem(15px);
            }
        }
    }
    .copyright {
        margin-top: rem(50px);
        display: flex;
        justify-content: space-between;
        flex-wrap:wrap;
        gap:10px;

        a {
            &:hover {
                color: $green;

                svg, path {
                    fill: $green;
                }
            }
        }

        .menu__socials {
            display: flex;
            gap:rem(25px);
        }

        span {
            margin-right: rem(20px);
        }
    }
}