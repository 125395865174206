.tele__hero {
    min-height:100vh;
    padding-top:rem(420px);
    padding-bottom:rem(420px);
    position: relative;
    isolation: isolate;
    
    @include media-max(640px) {
        padding-top:rem(200px);
        padding-bottom:rem(200px);
        min-height: unset;
    }
    .mask {
        display: block;
        position: absolute;
        z-index: -1;
        inset:0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .gradient {
            position: absolute;
            bottom:0;
            width: 100%;
            height:100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 68.23%, #000000 95.83%);
        }
    }

    .wrapper {
        max-width: 1280px;
        margin-inline: auto;
    }
    .hero__text {
        max-width: 60%;
        padding-top: rem(90px);
    }
    .hero_gradient_text {
        width:rem(1000px);
    }
}
.tele__info {
    min-height: 100vh;
    position: relative;

    .wrapper {
        position: absolute;
        top: 0;
        width: 100%;
        
        .content {
            max-width: 1280px;
            margin-inline: auto;
            display: grid;
            grid-template-columns: repeat(2, minmax(0,1fr));
            gap:100px;

            @include media-max(640px) {
                padding-top: 0;
            }
    
            & > div {
                grid-column: 2/-1;
            }
            .dots {
                display: flex;
                gap:rem(16px);
            }
        }
    }
    .map__svg {
        @include media-max(640px) {
            transform: scale(1.7);
            transform-origin: 50% 0;
        }
        [class*='uk-animation-'] {
            animation-duration: 1.5s;
            animation-timing-function: ease-out;
            animation-fill-mode: both;
        }
        svg {
            translate:0 -420px;
            pointer-events: none;

            @include media-max(640px) {
                translate: 0 -200px;
            }
        }
    }

    .info__text {
        padding-top: rem(20px);
    }
    .dots {
        .dot {
            width:rem(16px);
            height:rem(16px);
            display: block;
            border-radius: 1000px;
            background-color: $dred;
            
            &.red {
                background-color: $dred;
            }
            &.blue {
                background-color: $dblue;
            }
            &.green {
                background-color: $dgreen;
            }
        }
    }

    .numbers {
        display: grid;
        grid-template-columns: repeat(3, minmax(0,1fr));
        gap:100px;
        color: #68767C;
        line-height: 1;
        width:103vw;
        position: relative;
        left:50%;
        translate: -50% 0;
        margin-top: -1200px;
        
        @include media-max(960px) {
            margin-top: -1100px;
            margin-inline: 5%;
            width: 90vw;
            left: 0;
            translate: 0 0;
        }
        @include media-max(640px) {
            margin-top: rem(-700px);
            grid-template-columns: 1fr;
            gap:20px;
        }
        @include media-max(450px) {
            margin-top: rem(-450px);
        }

        .col1 {
            text-align: right;

            @include media-max(640px) {
                text-align: center;
            }
        }
        .col2 {
            text-align: left;

            @include media-max(640px) {
                text-align: center;
            }
        }
        .col3 {
            text-align: left;
            
            @include media-max(640px) {
                text-align: center;
            }
        }

        .value {
            color:#000;
            text-shadow:
                1px 1px 1px $green,
                -1px -1px 1px $green,
                1px -1px 1px $green,
                -1px 1px 1px $green;

            &.full {
                color: $green;
            }
            @include media-max(960px) {
                font-size: rem(70px);
            }
            @include media-max(640px) {
                font-size: rem(40px);
            }
        }
    }
    .target__point {
        padding-top: rem(240px);
        text-align: center;
        position: relative;
        z-index: 4;

        @include media-max(1500px) {
            padding-top: rem(380px);
        }
        @include media-max(960px) {
            padding-top: rem(150px);
        }
        @include media-max(640px) {
            padding-top: rem(75px);
        }

        .target {
            padding: rem(75px);
            background: rgb(0,0,0);
            background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 5%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 5%, rgba(0,0,0,0) 100%);
            background: linear-gradient(0deg, rgba(0,0,0,1) 5%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            
            @include media-max(960px) {
                padding: rem(40px 75px);
            }
            
            img {
                width: rem(125px);
                -webkit-animation: rotate-center 2.6s linear infinite both;
                animation: rotate-center 2.6s linear infinite both;
            }
        }
    }
}
.tele__analyse {
    .wheel {
        display: block;
        margin-inline: auto;
    }
}
.tele__data {
    min-height: 130vh;
    display: flex;
    align-items: center;
    align-content: center;
    color:#68767C;

    @include media-max(640px) {
        display: grid;
        gap:rem(60px);
    }

    .wrapper {
        max-width: 1280px;
        width: 100%;
        margin-inline: auto;
        display: grid;
        grid-template-columns: 5fr 3fr;

        @include media-max(640px) {
            grid-template-columns: 1fr;
        }
    }

    .mask {
        display: block;
        position: absolute;
        z-index: -1;
        inset:0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @include media-max(640px) {
            background-image: none !important;
        }
    }
    .label {
        display: grid;
        grid-template-columns: 1fr 4fr 5fr;
        align-items: center;
        align-content: center;
        gap:rem(20px);margin-bottom: rem(20px);
    }
    .data__items {
        display: grid;
        gap:8px;

        .item {
            position: relative;
            isolation: isolate;

            &:hover {
                .bg {
                    img, svg {
                        color: $green;
                    }
                }
            }
            
            .bg {
                display: grid;
                grid-template-columns: 1fr 4fr 5fr;
                align-items: center;
                align-content: center;
                gap:rem(20px);
                border-radius:18px;
                padding: rem(20px);
                background-color: #000;

                img,svg {
                    display: block;
                    margin-inline: auto;
                    transition: $t-all;
                }
            }
            
            &:nth-child(odd) {
                &:after {
                    content: '';
                    display: block;
                    width: calc(100% + 2px);
                    height: calc(100% + 2px);
                    border-radius:18px;
                    background: linear-gradient(90deg, #4D5E64 34.49%, rgba(0, 0, 0, 0) 75.09%);
                    position: absolute;
                    inset:-1px;
                    z-index: -1;
                }
            }

            .title {
                color:#fff;
            }
        }
    }
}
.tele__automise {
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;

    .wrapper {
        max-width: 1280px;
        width: 100%;
        margin-inline: auto;
        text-align: center;
        font-weight: 700;
        line-height: 1.2;
        padding-block: rem(300px);
        
        @include media-max(640px) {
            padding-block: rem(90px);
        }
    }

    .mask {
        display: block;
        position: absolute;
        z-index: -1;
        inset:0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
.tele__analyse {
    font-weight: 700;
    text-align: center;
    padding-block: rem(150px);

    @include media-max(640px) {
        padding-block: rem(70px);
    }
}
.tele__blocks {
    padding-block: rem(150px);
    
    .wrapper {
        font-weight: 700;
        text-align: center;
        margin-bottom: rem(100px);
    }

    .tele__cards {
        display: grid;
        grid-template-columns: repeat(2, minmax(0,1fr));

        @include media-max(640px) {
            grid-template-columns: 1fr;
        }

        .card {
            position: relative;
            isolation: isolate;

            img {
                width: 100%;
            }

            .title {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: flex-end;
                align-content: flex-end;
                padding: rem(50px);
                font-weight: bold;
                line-height: 1.1;
                background-image: linear-gradient(94.07deg, #88FC6C 14.4%, #00FFAC 57.89%, #C7FF2A 107.05%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                &.red {
                    background-image: linear-gradient(94.07deg, #FF3D57 14.4%, #DD42FF 57.89%, #FF3D57 107.05%);
                }
                &.blue {
                    background-image: linear-gradient(94.07deg, #00A4EF 14.4%, #6005FE 57.89%, #D93FFF 107.05%);
                }
                &.orange {
                    background-image: linear-gradient(94.07deg, #FAFD5E 14.4%, #FFB822 57.89%, #FFF62A 107.05%);
                }
            }
        }
    }
}
.tele__connection {
    text-align: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include media-max(640px) {
        text-align: left;
    }
    
    .title {
        font-weight: 700;
        margin-bottom: rem(70px);
    }

    .connections_svg {
        padding-top: rem(200px);
        padding-bottom: rem(200px);
        position: relative;

        @include media-max(640px) {
            display: grid;
        }
    }

    .connetions__cards {
        @include media-max(640px) {
            order:10;
        }

        .container {
            cursor: auto;
            position: absolute;

            @include media-max(640px) {
                position: static;
                
                &:nth-of-type(1n) {
                    padding-left: rem(50px);
                }
                &:nth-of-type(2n) {
                    padding-left: rem(60px);
                }
                &:nth-of-type(3n) {
                    padding-left: rem(70px);
                }
                &:nth-of-type(4n) {
                    padding-left: rem(80px);
                }
                &:nth-of-type(5n) {
                    padding-left: rem(90px);
                }
                &:nth-of-type(6n) {
                    padding-left: rem(100px);
                }
            }
        }
        .container1 {
            left: 57vw;
            top: rem(250px);

            @include media-max(1200px) {
                top: rem(200px);
            }
            @include media-max(960px) {
                top: rem(210px);
            }
        }
        .container2 {
            left: 75vw;
            top: rem(550px);
            
            @include media-max(1200px) {
                top: rem(390px);
            }
            @include media-max(960px) {
                left:73vw;
                top: rem(327px);
            }
        }
        .container3 {
            left: 37vw;
            top: rem(750px);
            
            @include media-max(1200px) {
                top: rem(520px);
            }
            @include media-max(960px) {
                left: 35vw;
                top:rem(400px);
            }
        }
        .container4 {
            left: 36vw;
            top: rem(1060px);
            
            @include media-max(1200px) {
                top: rem(700px);
            }
            @include media-max(960px) {
                left: 34vw;
                top: rem(525px);
            }
        }
        .container5 {
            display: grid;
            left: 68vw;
            top: rem(900px);
            
            @include media-max(1200px) {
                top: rem(600px);
            }
            @include media-max(960px) {
                left: 66vw;
                top: rem(455px);
            }
        }
        .block {
            position: relative;
            display: inline-block;
            padding:rem(20px 45px);
            background: rgb(0,0,0);
            background: -moz-linear-gradient(90deg, rgba(0,0,0,1) 75%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(90deg, rgba(0,0,0,1) 75%, rgba(0,0,0,0) 100%);
            background: linear-gradient(90deg, rgba(0,0,0,1) 75%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            border-radius: 12px;
            text-align: center;
            margin: 12px 0;

            @include media-max(960px) {
                padding: rem(5px 10px);
            }
            
            * {
                text-align: left;
            }

            .t10 {
                color: #9FA4A6;
            }

            &:after {
                content: '';
                display: block;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                border-radius: 12px;
                background: linear-gradient(90deg, #4D5E64 34.49%, rgba(0, 0, 0, 0) 75.09%);
                position: absolute;
                inset: -1px;
                z-index: -1;
            }
            &:before {
                content:'';
                display: block;
                width: 15px;
                height: 15px;
                border-top: 1px solid #4D5E64;
                border-left: 1px solid #4D5E64;
                position: absolute;
                top: 50%;
                left:0;
                translate: -50% -50%;
                rotate: -45deg;
                background-color: #000;

                @include media-max(960px) {
                    width: 9px;
                    height: 9px;
                }
                @include media-max(640px) {
                    left:rem(30px);
                    top:0;
                    translate: 0 -50%;
                    rotate: 45deg;
                }
            }

            .grid {
                display: grid;
                grid-template-columns: 2fr 1fr;
                gap:10px;
                margin:0;

                .icons {
                    height: 100%;
                    text-align: center;
                    display: grid;
                    justify-content: center;
                    justify-items: center;
                    align-content: space-around;
                }
            }
        }
    }
}
#lines {
    .path {
        stroke-dasharray: 3000;
        stroke-dashoffset: 3000;
        animation: dash 2s linear forwards;
      }
      
      @keyframes dash {
        to {
          stroke-dashoffset: 0;
        }
      }
}
.telemetrics__page {
    .wrapper {
        @include media-max(1300px) {
            max-width: 90%;
            margin-inline: auto;
            right: 0;
            left: 0;
        } 
    }
}