.iot__page {
    .section {
        .wrapper {
            max-width: 1280px;
            margin-right: auto;
            margin-left: auto;

            @include media-min(1921px) {
                max-width: rem(1800px);
            }

            @include media-max(1300px) {
                max-width: 90%;
            }
        }
    }
    .hero {
        background-position: center top;
        background-size: cover;
        position: relative;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: rem(250px);
            background: rgb(0,0,0);
            background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            background: linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            position: absolute;
            inset: 0;
            top: auto;
            z-index: 9;
        }
        .padding_bottom_300 {
            @include media-max(640px) {
                padding-bottom: rem(100px);
            }
        }
    }
    .green__gradient {
        background: linear-gradient(94.07deg, #88FC6C 10%, #00FFAC 53.5%, #C7FF2A 102.66%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
    .hero__title {
        background-size: 120%;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
    .hero__text {
        max-width: 50%;

        @include media-max(960px) {
            max-width: 100%;
        }
    }
    .diagonals {
        position: absolute;
        width: 100%;
        height: 100vh;
        inset:0;

        .diagonal {
            height: 100px;
            width: 2px;
            position: absolute;
            transform-origin: 0 0;
            // background-color: red !important;
            // height: 3000px !important;

            &:after {
                content:'';
                display: block;
                width: 100%;
                height: 100%;
                background-color: $green;
                position: absolute;
                inset: 0;
                top:auto;
            }

            &.diagonal1 {
                height: 1px;
                left:rem(240px);
                rotate: -45deg;
                transition: height 1s ease-in-out;

                @include media-max(1200px) {
                    left:rem(-8px);
                }
                @include media-max(960px) {
                    left:rem(-264px);
                }
                @include media-max(640px) {
                    left: rem(-145px);
                }
                @include media-max(450px) {
                    left: rem(-208px);
                }

                &:after {
                    transition: height 1.5s ease-in-out;
                    transition-delay: 1s;
                }

                &.active {
                    height:2500px;
                    
                    &:after {
                        height: 0;
                    }
                }
            }
            &.diagonal2 {
                height: 2500px;
                left:rem(282px);
                rotate: -45deg;
                transition: height 1s ease-in-out;
                transition-delay: 1s;

                @include media-max(1200px) {
                    left: rem(30px);
                }
                @include media-max(960px) {
                    left:rem(-220px);
                }
                @include media-max(640px) {
                    left: rem(-120px);
                }
                @include media-max(450px) {
                    left: rem(-180px);
                }

                &:after {
                    transition: height 1.5s ease-in-out;
                    height: 0;
                }

                &.active {
                    height:0px;

                    &:after {
                        height: 100%;
                    }
                }
            }
            &.diagonal3 {
                height: 1px;
                left:rem(417px);
                rotate: -45deg;
                transition: height 1s ease-in-out;

                @include media-max(1200px) {
                    left:rem(130px);
                }
                @include media-max(960px) {
                    left:rem(-110px);
                }
                @include media-max(640px) {
                    left:rem(-40px);
                }
                @include media-max(450px) {
                    left:rem(-110px);
                }

                &:after {
                    transition: height 1.5s ease-in-out;
                    transition-delay: 1s;
                }

                &.active {
                    height:2500px;

                    &:after {
                        height: 0;
                    }
                }
            }
            &.diagonal4 {
                height: 2500px;
                left:rem(564px);
                rotate: -45deg;
                transition: height 1s ease-in-out;
                transition-delay: 1s;

                @include media-max(1200px) {
                    left: rem(256px);
                }
                @include media-max(960px) {
                    left: rem(20px);
                }
                @include media-max(640px) {
                    left: rem(50px);
                }
                @include media-max(450px) {
                    left: rem(-20px);
                }

                &:after {
                    transition: height 1.5s ease-in-out;
                    height: 0;
                }

                &.active {
                    height:0px;

                    &:after {
                        height: 100%;
                    }
                }
            }

            &.diagonal5 {
                top: rem(760px);
                left:rem(805px);
                height:1px;
                rotate: 45deg;
                transition: height 1s ease-in-out;

                @include media-max(1200px) {
                    top:rem(620px);
                    left:rem(440px);
                }
                @include media-max(960px) {
                    top:rem(670px);
                    left:rem(226px);
                }
                @include media-max(640px) {
                    top:rem(450px);
                    left:rem(200px);
                }
                @include media-max(450px) {
                    top:rem(430px);
                    left:rem(120px);
                }

                &:after {
                    transition: height 1.5s ease-in-out;
                    transition-delay: 1s;
                }

                &.active {
                    height:1500px;
                    
                    &:after {
                        height: 0;
                    }
                }
            }
            &.diagonal6 {
                top: rem(780px);
                left:rem(835px);
                height:1500px;
                rotate: 45deg;
                transition: height 1s ease-in-out;
                transition-delay: 1s;

                @include media-max(1200px) {
                    top:rem(650px);
                    left:rem(450px);
                }
                @include media-max(960px) {
                    top:rem(690px);
                    left:rem(246px);
                }
                @include media-max(640px) {
                    top:rem(470px);
                    left:rem(210px);
                }
                @include media-max(450px) {
                    top:rem(450px);
                    left:rem(130px);
                }

                &:after {
                    transition: height 1.5s ease-in-out;
                    height: 0;
                }

                &.active {
                    height:0px;

                    &:after {
                        height: 100%;
                    }
                }
            }
            &.diagonal7 {
                top:rem(845px);
                left:rem(885px);
                height:1px;
                rotate: 45deg;
                transition: height 1s ease-in-out;

                @include media-max(1200px) {
                    top:rem(680px);
                    left:rem(510px);
                }
                @include media-max(960px) {
                    top:rem(730px);
                    left:rem(306px);
                }
                @include media-max(640px) {
                    top:rem(510px);
                    left:rem(240px);
                }
                @include media-max(450px) {
                    top:rem(480px);
                    left:rem(170px);
                }

                &:after {
                    transition: height 1.5s ease-in-out;
                    transition-delay: 1s;
                }

                &.active {
                    height:1500px;
                    
                    &:after {
                        height: 0;
                    }
                }
            }

            &.diagonal8 {
                top: rem(470px);
                left: rem(1100px);
                height:1px;
                rotate: -135deg;
                transition: height 1s ease-in-out;

                @include media-max(1200px) {
                    top:rem(360px);
                    left:rem(700px);
                }
                @include media-max(960px) {
                    top:rem(400px);
                    left:rem(495px);
                }
                @include media-max(640px) {
                    top:rem(270px);
                    left:rem(380px);
                }
                @include media-max(450px) {
                    top:rem(260px);
                    left:rem(300px);
                }

                &:after {
                    transition: height 1.5s ease-in-out;
                    transition-delay: 1s;
                }

                &.active {
                    height:1500px;
                    
                    &:after {
                        height: 0;
                    }
                }
            }
            &.diagonal9 {
                top: rem(540px);
                left: rem(1200px);
                height: 1px;
                rotate: -135deg;
                transition: height 1s ease-in-out;

                @include media-max(1200px) {
                    top:rem(430px);
                    left:rem(760px);
                }
                @include media-max(960px) {
                    top:rem(470px);
                    left:rem(570px);
                }
                @include media-max(640px) {
                    top:rem(320px);
                    left:rem(430px);
                }
                @include media-max(450px) {
                    top:rem(305px);
                    left:rem(350px);
                }

                &:after {
                    transition: height 1.5s ease-in-out;
                    transition-delay: 1s;
                }

                &.active {
                    height:1500px;
                    
                    &:after {
                        height: 0;
                    }
                }
            }
            &.diagonal10 {
                top: rem(560px);
                left: rem(1220px);
                height: 1500px;
                rotate: -135deg;
                transition: height 1s ease-in-out;
                transition-delay: 1s;

                @include media-max(1200px) {
                    top:rem(450px);
                    left:rem(780px);
                }
                @include media-max(960px) {
                    top:rem(490px);
                    left:rem(590px);
                }
                @include media-max(640px) {
                    top:rem(340px);
                    left:rem(440px);
                }
                @include media-max(450px) {
                    top:rem(320px);
                    left:rem(360px);
                }

                &:after {
                    transition: height 1.5s ease-in-out;
                    height: 0;
                }

                &.active {
                    height:0px;

                    &:after {
                        height: 100%;
                    }
                }
            }
        }
    }
    .operation {
        @include media-max(640px) {
            padding-top: rem(0px);
            padding-bottom: rem(0px);
            min-height: 1px;

            .right {
                text-align: center;
            }
        }
        .text {
            max-width: 50%;
            margin-left: auto;

            @include media-max(960px) {
                max-width: 100%;
            }
        }

        .padding_top_100 {
            @include media-max(640px) {
                padding-top: rem(40px);
            }
        }
    }
    .numbers {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        isolation: isolate;

        @include media-max(640px) {
            padding-bottom: rem(0px);
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: rem(250px);
            background: rgb(0,0,0);
            background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            background: linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            position: absolute;
            inset: 0;
            top: auto;
            z-index: -1;
        }
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: rem(150px);
            background: rgb(0,0,0);
            background: -moz-linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            background: -webkit-linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            background: linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            position: absolute;
            inset: 0;
            bottom: auto;
            z-index: -1;
        }

        .numbers__wrapper {
            background: radial-gradient(circle at 100% 0%,  rgba(136,252,108,1) 0%,rgba(0,0,0,0) 30%);
            border-radius: 30px;

            .row {
                display: grid;
                grid-template-columns: 2fr 1fr;
                gap:rem(30px);
                align-items: center;
                padding-block: rem(40px);

                &:not(:last-child) {
                    border-bottom: 1px solid $green;
                }

                @include media-max(960px) {
                    grid-template-columns: 1fr;
                }
            }
            .number {
                color: $green;
                -webkit-text-fill-color: transparent;
                -webkit-text-stroke-width: 2px;
                -webkit-text-stroke-color: $green;
            }
        }
    }
    .monitoring {
        @include media-max(640px) {
            padding-bottom: rem(50px);
        }
        @include media-max(450px) {
            padding-top: rem(100px);
        }

        .padding_bottom_200 {
            @include media-max(640px) {
                padding-bottom: rem(50px);
            }
        }
        .cards__wrapper {
            display: grid;
            grid-template-columns: repeat(8, minmax(0,1fr));
            grid-template-rows: repeat(3, minmax(0, 1fr));
            gap:rem(30px);

            @include media-max(960px) {
                grid-template-columns: 1fr;
            }

            .card {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                grid-column: span 3;
                grid-row:span 1;
                overflow: hidden;
                border:1px solid $grey60;
                border-radius: rem(40px);

                @include media-max(960px) {
                    grid-column: span 1;
                    aspect-ratio: 3/2;
                    border-radius: rem(20px);
                }

                &.large {
                    grid-column: 1 / span 5;
                    grid-row: span 2;

                    @include media-max(960px) {
                        grid-column: 1/ span 1;
                        grid-row: span 1;
                    }
                }
                &.long {
                    grid-column: span 5;

                    @include media-max(960px) {
                        grid-column: span 1;
                    }
                }

                .card__header {
                    position: relative;
                    grid-column: 1/2;
                    grid-row: 1/2;
                    padding-block: rem(20px);
                    height: min-content;
    
                    .card__icon {
                        position: absolute;
                        top:0;
                        bottom:0;
                        left: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        align-content: center;
                        padding-inline: rem(30px);

                        @include media-max(1200px) {
                            padding-inline: rem(15px);
                            scale:0.7;
                        }
                        @include media-max(450px) {
                            align-content:flex-start;
                            align-items:flex-start;
                        }
                    }

                    .card__title, .card__subtitle {
                        @include media-max(450px) {
                            max-width: 70%;
                            margin-inline: auto;
                            color: #fff;
                        }
                    }
                    .card__title {
                        .t50 {
                            @include media-max(450px) {
                                font-size:rem(26px);
                                margin-bottom: rem(10px);
                            }
                        }
                    }
                }
                .card__image {
                    grid-column: 1/2;
                    grid-row: 1/2;
                    z-index: -1;
                    
                    img {
                        width: 100%;
                        scale: 1.1;

                        @include media-max(960px) {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
    .needs {
        isolation: isolate;

        @include media-max(640px) {
            text-align: center;
        }

        @include media-max(450px) {
            padding-top: rem(50px);
        }

        .needs__img {
            position: relative;
            z-index: -1;
            img {
                width: 100%;
            }
        }

        .margin_top_n300 {
            margin-top: rem(-100px);
        }
    }
    .assistant {
        isolation: isolate;

        @include media-max(640px) {
            .right {
                text-align: center;
            }
        }

        @include media-max(450px) {
            padding-top: rem(0px);
        }

        .margin_top_n300 {
            @include media-max(960px) {
                margin-top: rem(-150px) !important;
            }
            @include media-max(450px) {
                margin-top: 0 !important;
            }
        }

        .text {
            max-width: 50%;
            margin-left: auto;

            @include media-max(960px) {
                max-width: 100%;
            }
        }
        .assistant__img {
            position: relative;
            z-index: -1;

            img {
                width: 100%;
            }

            .buttons {
                @include media-max(640px) {
                    position: absolute;
                    inset:0;
                    margin: auto;
                    top: 53%;
                    translate: 0 -50%;
                    display: grid;
                    place-content: center;
                    place-items: center;
                }
                .button__item {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    gap:rem(20px);
                    position: absolute;
                    z-index: 9;
                    top:0;
                    left:0;

                    .button__icon {
                        img {
                            width: rem(65px);

                            @include media-max(1200px) {
                                width: rem(45px);
                            }
                            @include media-max(960px) {
                                width: rem(30px);
                            }
                        }
                    }
                    .t18 {
                        @include media-max(1200px) {
                            font-size: rem(15px);
                        }
                        @include media-max(960px) {
                            font-size: rem(12px);
                        }
                    }

                    &:nth-child(1) {
                        left:30%;
                        top:rem(840px);

                        @include media-max(1200px) {
                            left: 30%;
                            top: rem(495px);
                        }
                        @include media-max(960px) {
                            left: 30%;
                            top: rem(320px);
                        }
                    }
                    &:nth-child(2) {
                        left:61%;
                        top:rem(1006px);

                        @include media-max(1200px) {
                            left: 54%;
                            top: rem(595px);
                        }
                        @include media-max(960px) {
                            left:54%;
                            top:rem(380px);
                        }
                    }
                    &:nth-child(3) {
                        left:42%;
                        top:rem(1270px);

                        @include media-max(1200px) {
                            left: 41%;
                            top: rem(750px);
                        }
                        @include media-max(960px) {
                            left:41%;
                            top:rem(480px);
                        }
                    }
                    &:nth-child(4) {
                        left:18%;
                        top:rem(1480px);

                        @include media-max(1200px) {
                            left: 18%;
                            top: rem(870px);
                        }
                        @include media-max(960px) {
                            left:18%;
                            top:rem(560px);
                        }
                    }
                    &:nth-child(5) {
                        left:71.7%;
                        top:rem(1480px);

                        @include media-max(1200px) {
                            left:71%;
                            top: rem(870px);
                        }
                        @include media-max(960px) {
                            left:71%;
                            top:rem(560px);
                        }
                    }
                    &:nth-child(6) {
                        left:36%;
                        top:rem(1690px);

                        @include media-max(1200px) {
                            left:36%;
                            top:rem(1000px);
                        }
                        @include media-max(960px) {
                            left:36%;
                            top:rem(640px);
                        }
                    }
                    @include media-max(640px) {
                        position: static !important;
                        margin-bottom: rem(20px);

                        &:nth-child(odd) {
                            margin-left:rem(20px);
                        }
                        &:nth-child(even) {
                            margin-left:rem(-20px);
                        }
                    }
                }
            }
        }
    }
    .iot__cards {
        .cards__wrapper {
            display:grid;
            grid-template-columns: repeat(2, minmax(0,1fr));
            gap:rem(60px);

            @include media-max(960px) {
                grid-template-columns: 1fr;
            }
        }
        .card__item {
            border-radius:rem(30px);
            overflow: hidden;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;

            & > * {
                grid-column: 1/-1;
                grid-row: 1/-1;
            }

            .card__content {
                height: 100%;
                display: grid;
                align-content: space-between;

                .card__header {
                    padding: rem(30px);
                    padding-right: rem(60px);
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: space-between;
                }
                .card__desc {
                    padding:rem(30px);
                }
            }
            .card__image {
                position: relative;
                z-index: -1;

                img {
                    width: 100%;
                }
                .main {
                    position: absolute;
                }
                .mask {
                    position: absolute;
                    inset:0;

                    @include media-max(640px) {
                        top:auto;
                        height: 100%;
                    }
                }
                .glow {
                    position: absolute;
                    right:0;
                    top:0;
                    z-index: 2;
                    height: rem(400px);
                    width: rem(400px);
                    mix-blend-mode: color;
                }
            }
        }
    }
    .scheme {

        @include media-max(960px) {
            padding-top: rem(100px);
        }
        @include media-max(640px) {
            padding-top: rem(20px);
            padding-bottom: rem(20px);
            min-height: 1px;
        }
        svg {
            width: 100% !important;
            fill: unset !important; 

            * {
                transform-origin: 50% 50%;
            }
        }

        @include media-max(640px) {
            .padding_bottom_200 {
                padding-bottom: rem(60px);
            }
        }
    }
    .standards {
        background-position-x: center;
        background-position-y: 200%;
        background-size: 100%;
        position: relative;
        isolation: isolate;

        @include media-max(640px) {
            padding-top: rem(50px);
            padding-bottom: rem(50px);

            * {
                text-align: center;
            }
        }

        .padding_bottom_200 {
            @include media-max(640px) {
                padding-bottom: rem(50px);
            }
        }

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: rem(250px);
            background: rgb(0,0,0);
            background: -moz-linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            background: -webkit-linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            background: linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            position: absolute;
            inset: 0;
            bottom: auto;
            z-index: -1;
        }

        .content__wrapper {
            isolation: isolate;
            position: relative;

            .main__grid {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                position: relative;
                isolation: isolate;
                align-items: center;
                align-content: center;

                & > * {
                    grid-column: 1/-1;
                    grid-row: 1/-1;
                }
            }
            .phone__image {
                z-index: -1;
                display: flex;
                place-items: center;
                place-content: center;

                * {
                    transform-origin: 50% 50%;
                }

                @include media-max(640px) {
                    img, svg {
                        max-width: 90%;
                    }
                }
            }
            .subgrid {
                display: grid;
                grid-template-columns: repeat(2, minmax(0,1fr));
                align-items: center;
                align-content: center;
                gap:rem(180px);
                
                @include media-max(960px) {
                    gap:rem(80px);
                }
                @include media-max(640px) {
                    grid-template-columns:1fr;
                    gap:0;
                }

                .snap__image {
                    border-radius: rem(50px);

                    @include media-max(960px) {
                        border-radius: rem(20px);
                    }
                    @include media-max(640px) {
                        margin-top:0 !important;
                        margin-bottom:rem(10px) !important;
                        margin-inline:auto !important;
                        max-width: 60%;
                        display: block;
                        border-radius:rem(20px) !important;
                    }
                }

                .width100 {
                    width: 100%;
                    border-radius: 0 rem(50px) rem(50px) 0;
                }

            }
        }
    }
    .gps {
        position: relative;
        z-index: 9;

        .vectors__desktop {
            @include media-max(960px) {
                display:none;
            }
        }
        .vectors__mobile {
            display:none;

            @include media-max(960px) {
                display:block;
            }
        }

        @include media-max(450px) {
            padding-top: rem(50px);
            padding-bottom: 0;
        }

        .padding_bottom_200 {
            padding-bottom: rem(20px);
        }

        .margin_top_145 {
            @include media-max(1200px) {
                margin-top: rem(85px);
            }
        }

        .graphic {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;

            img, svg {
                grid-column: 1/-1;
                grid-row: 1/-1;
                width: 100%;

                fill: unset !important
            }
        }
    }
    .findus {
        background-size: cover;
        background-position: center top;
        position: relative;

        @include media-max(640px) {
            * {
                text-align: center;
            }
        }

        @include media-max(450px) {
            margin-top: rem(-100px);
            padding-top: 0;

            .padding_top_400 {
                padding-top: rem(200px);
            }
        }

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: rem(250px);
            background: rgb(0,0,0);
            background: -moz-linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            background: -webkit-linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            background: linear-gradient(0deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 90%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            position: absolute;
            inset: 0;
            top:rem(450px);
            bottom: auto;
            z-index: 1;

            @include media-max(450px) {
                top:0;
            }
        }

        .text {
            max-width: 50%;
            margin-left: auto;

            @include media-max(960px) {
                max-width: 100%;
            }
        }
    }

    .t150 {
        @include media-max(640px) {
            font-size:rem(80px);
        }
        @include media-max(640px) {
            font-size: rem(60px);
        }
    }
    .t120 {
        @include media-max(640px) {
            font-size: rem(60px);
        }
        @include media-max(450px) {
            font-size: rem(40px);
        }
    }
    .t60 {
        @include media-max(640px) {
            font-size:rem(24px);
        }
        @include media-max(640px) {

        }
    }
    *:after, *:before {
        pointer-events: none !important;
    }
}