//
// Component: Table
//
// ========================================================================


// Variables
// ========================================================================

$table-header-cell-font-size:                    $global-small-font-size !default;
$table-header-cell-font-weight:                  normal !default;
$table-header-cell-color:                        $global-muted-color !default;

//
// New
//

$table-striped-border-width:                     $global-border-width !default;
$table-striped-border:                           $global-border !default;


// Component
// ========================================================================



// @mixin hook-table-cell(){}

// @mixin hook-table-footer(){}

// @mixin hook-table-caption(){}

// @mixin hook-table-row-active(){}


// Style modifiers
// ========================================================================

// @mixin hook-table-divider(){}



// @mixin hook-table-hover(){}


// Size modifier
// ========================================================================

// @mixin hook-table-small(){}

// @mixin hook-table-large(){}


// Miscellaneous
// ========================================================================




// Inverse
// ========================================================================

// @mixin hook-inverse-table-header-cell(){}
// @mixin hook-inverse-table-caption(){}
// @mixin hook-inverse-table-row-active(){}
// @mixin hook-inverse-table-divider(){}

// @mixin hook-inverse-table-hover(){}