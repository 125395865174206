@keyframes empowerText {
    0% {
        transform:translate(0, 0);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    10% {
        transform:translate(0, 0);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    33% {
        transform:translate(0, rem(-120px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    43% {
        transform:translate(0, rem(-120px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    66% {
        transform:translate(0, rem(-240px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    76% {
        transform:translate(0, rem(-240px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    100% {
        transform:translate(0, rem(-360px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
}
@keyframes heroText {
    0% {
        transform:translate(0, 0);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    12% {
        transform:translate(0, 0);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    25% {
        transform:translate(0, rem(-50px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    37% {
        transform:translate(0, rem(-50px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    50% {
        transform:translate(0, rem(-100px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    62% {
        transform:translate(0, rem(-100px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    75% {
        transform:translate(0, rem(-150px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    87% {
        transform:translate(0, rem(-150px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    100% {
        transform:translate(0, rem(-200px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
}
@keyframes empowerText1200 {
    0% {
        transform:translate(0, 0);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    10% {
        transform:translate(0, 0);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    33% {
        transform:translate(0, rem(-100px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    43% {
        transform:translate(0, rem(-100px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    66% {
        transform:translate(0, rem(-200px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    76% {
        transform:translate(0, rem(-200px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    100% {
        transform:translate(0, rem(-300px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
}
@keyframes empowerText960 {
    0% {
        transform:translate(0, 0);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    10% {
        transform:translate(0, 0);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    33% {
        transform:translate(0, rem(-255px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    43% {
        transform:translate(0, rem(-255px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    66% {
        transform:translate(0, rem(-510px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    76% {
        transform:translate(0, rem(-510px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    100% {
        transform:translate(0, rem(-765px));
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
}
@keyframes empowerBG {
    0% {
        transform:scale(1.1);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    10% {
        transform:scale(1.1);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    33% {
        transform:scale(1.2);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    43% {
        transform:scale(1.2);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    66% {
        transform:scale(1.3);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    76% {
        transform:scale(1.3);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    100% {
        transform:scale(1.4);
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
}
@keyframes empowerButton {
    0% {
        transform:translate(0,-20px);
        opacity:0;
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    90% {
        transform:translate(0,-20px);
        opacity:0;
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    100% {
        transform:translate(0,-0);
        opacity:1;
        animation-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
}
$empowerAnimationTime: 5s;
.fadein {
    display:none;
}
// hero
.hero:not(.new) {
    min-height:100vh;
    background-size: contain;
    // background-position-x: 64%;
    // background-position-y: -11.4rem;
    isolation: isolate;
    overflow-y: hidden;

    .circles__bg_hero {
        position: absolute;
        right:0;
        left:0;
        top:50%;
        width: 100%;
    }
    
    .appendix {
        display: flex;
        align-items: center;
    }
    .tagline {
        display: inline-block;
        line-height: rem(50px);
        height:rem(50px);
        position: relative;
        top:rem(-5px);
        overflow:hidden;

        .tagline__content {
            animation-name: heroText;
            animation-duration: 8s;
            animation-iteration-count: infinite;

            .line {
                text-align: left;
            }
        }
    }

    .wrapper {
        max-width:1280px;
        margin-right: auto;
        margin-left: auto;

        @include media-max(1440px) {
            max-width:90%;
        }
    }
    
    .text__wrapper {
        position: absolute;
        inset:0;
        margin: auto;
        height:100%;
        isolation: isolate;

        .mask {
            z-index: 9;
        }
    }
    .text__layer {
        position: absolute;
        inset:0;
        margin: auto;
        width:100%;
        height:100%;
        text-transform: uppercase;
        color:$grey80;
        font-size:rem(16px);
        letter-spacing: 15px;
        opacity:0.7;
        z-index: 7;

        @include media-max(960px) {
            display: none !important;
        }

        & > div {
            position:absolute;
            width:max-content;
            transition: $t-all-extra-slow;

            &:nth-child(1) {
                top:20%;
                left:45%;
            }
            &:nth-child(2) {
                top:33%;
                right:2%;
            }
            &:nth-child(3) {
                top:33%;
                left:2%;
            }
            &:nth-child(4) {
                top:46%;
                left:4%;
            }
            &:nth-child(5) {
                top:52%;
                left:15%;
            }
            &:nth-child(6) {
                top:59%;
                left:2%;
            }
            &:nth-child(7) {
                top:59%;
                right:9%;
            }
            &:nth-child(8) {
                top:72%;
                left:1%;
            }
            &:nth-child(9) {
                top:72%;
                right:1%;
            }
            &:nth-child(10) {
                top:85%;
                right:9%;
            }
        }

        &.active {
            .move__left {
                transform: translate(rem(-20px),0);
                
                &.far {
                    transform: translate(rem(-40px),0);
                }
            }
            .move__right {
                transform: translate(rem(20px),0);
                
                &.far {
                    transform: translate(rem(40px),0);
                }
            }
        }
    }

    .preloader {
        position:absolute;
        inset:0;
        margin: auto;
        width:100%;
        height:100%;
        
        & > * {
            position:absolute;
            inset:0;
            margin: auto;
            width:rem(128px);
            height:rem(128px);
            display: block;
        }
        span {
            display: inline-flex;
            place-content: center;
            place-items: center;
        }
        img {
            -webkit-animation:spin 3s linear infinite;
            -moz-animation:spin 3s linear infinite;
            animation:spin 3s linear infinite;
        }
        @-moz-keyframes spin { 
            100% { -moz-transform: rotate(-360deg); } 
        }
        @-webkit-keyframes spin { 
            100% { -webkit-transform: rotate(-360deg); } 
        }
        @keyframes spin { 
            100% { 
                -webkit-transform: rotate(-360deg); 
                transform:rotate(-360deg); 
            } 
        }
    }
    
    .mask {
        z-index: -1;
        position:absolute;
        inset:0;
        margin: auto;
        width:100%;
        height:100%;
    }
    .mask2 {
        z-index: -1;
        position:absolute;
        inset:0;
        top:auto;
        bottom:0;
        margin: auto;
        width:100%;
        height:100%;
        background-repeat: no-repeat;
        background-position: center bottom;
    }

    .center {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        height:100%;

        & > * {
            flex:1;
        }

    }

    .video {
        position: absolute;
        width: 80%;
        top:auto;
        bottom:0;
        left:0;
        right:0;
        margin: auto;
        mix-blend-mode: screen;
        -webkit-mix-blend-mode: screen;

        @include media-max(640px) {
            width:130%;
            left:-15%;
        }
    }
}
.hero {
    &.new {
        position: relative;
        background-size:cover;
        background-position: center;
        background-repeat: no-repeat;
        isolation: isolate;
        min-height: unset;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: rem(220px);
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%);
            position: absolute;
            z-index: 2;
            right: 0;
            left: 0;
            bottom: 0;
            
            @include media-max(640px) {
                height: rem(80px);
            }
        }

        & > img {
            width: 100%;

            @include media-max(640px) {
                height: 70vh;
                object-fit: cover;
            }
        }
        .wrapper {
            position: absolute;
            inset:0;
            z-index: 3;
            margin: auto;
            width: 100%;
            height: 100%;
            display: flex;
            place-content: center;
            place-items: center;

            .flex {
                display: flex;
                align-items: center;
                align-content: center;
                line-height: 1;
                gap: 10px;
            }
        }
    }
}
// intelligent
.intelligent {
    .intelligent__gradient__text {
        background: url('../../assets/img/intelligent_grad_bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
}
// telemetrics
.telemetrics {
    display: grid;
    position: relative;
    isolation: isolate;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: rem(220px);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%);
        position: absolute;
        z-index: 2;
        right: 0;
        left: 0;
        bottom: 0;
        
        @include media-max(640px) {
            height: rem(80px);
        }
    }

    & > img {
        grid-column: 1/-1;
        grid-row: 1/-1;
        width: 100%;

        @include media-max(960px) {
            height: 100%;
            object-fit: cover;
        }
    }
    .wrapper {
        grid-column: 1/-1;
        grid-row: 1/-1;
        display: flex;
        height: 100%;
        place-content: center;
        place-items: center;
    }
    .disclaimer {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin-inline: auto;
        display: flex;
        justify-content: center;
        justify-items: center;
        z-index: 9;

        .flex {
            display: flex;
            align-items: center;
            align-content: center;
            gap: 20px;
        }
        .dot {
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 1000px;
            background-color: $green;
        }
    }
}

// iot
.iot {
    @include media-max(960px) {
        padding-bottom: 0;
    }
    
    .wrapper {
        max-width: 1280px;
        margin-inline: auto;

        @include media-max(1300px) {
            max-width: 90%;
        }
    }
    .iot_grad_text {
        background: url('../../assets/img/iot_grad_text.png');
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .grid__1_1 {
        @include media-max(960px) {
            grid-template-columns: 1fr;
            text-align: center;
        }
    }
}

// possibilities
.possibilities {
    position: relative;
    display: grid;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: rem(220px);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%);
        position: absolute;
        z-index: 2;
        right: 0;
        left: 0;
        bottom: 0;
        
        @include media-max(640px) {
            height: rem(80px);
        }
    }
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: rem(220px);
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%);
        position: absolute;
        z-index: 2;
        right: 0;
        left: 0;
        top: 0;
        
        @include media-max(640px) {
            height: rem(80px);
        }
    }

    .possibilities_grad_text {
        background: url('../../assets/img/possibilities_grad_text.png');
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    & > img {
        width: 100%;
        grid-column: 1/-1;
        grid-row: 1/-1;

        @include media-max(960px) {
            height: 100%;
            object-fit: cover;
        }
    }
    .wrapper {
        grid-column: 1/-1;
        grid-row: 1/-1;
        position: relative;
        z-index: 3;
        width: 100%;
        max-width: 1280px;
        margin-inline: auto;

        display: grid;
        grid-template-rows: 1fr auto 1fr;
    }

    .possibilites__main_element {
        
        .element {
            display: grid;
            align-items: center;
            align-content: center;
            text-align: center;
            isolation: isolate;

            img, svg {
                margin: auto;
            }

            & > * {
                position: relative;
                grid-column: 1/-1;
                grid-row:1/-1;

                &:last-child {
                    z-index: -1;
                }
            }
        }
    }

    .bottom__content {
        display: grid;
        align-content: end;
        align-items: end;
    }
}
// bigdata
.bigdata {
    position: relative;
    margin-top: rem(300px);
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;

    @include media-max(1200px) {
        margin-top: rem(200px);
    }
    @include media-max(960px) {
        min-height: 1px;
    }

    & > img {
        width: 100%;
    }

    .bigdata__overlay {
        position: absolute;
        width:100%;
        height: 100%;
        top:0;
        // position: relative;

        .wrapper {
            max-width: 1280px;
            margin-inline: auto;
            position: absolute;
            right:0;
            bottom: 0;
            left:0;

            @include media-max(1300px) {
                max-width: 90%;
            }
        }
    }

    .hero__bg {
        img {
            width: 100%;
        }
    }
    .hero__title {
        position: relative;
        overflow: hidden;
        .title {
            font-family: 'DM Sans';
            font-weight: 700;
            font-size: 16px;
            width: 104%;
            translate: -2% 0;
            
            .large {
                pointer-events: none;
                fill: transparent;
                stroke: #fff;
                stroke-width: 0.1px;
                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */
            }
        }
        .subtitle {
            font-family: 'DM Sans';
            font-weight:400;
            font-size: 16px;
            width: 50%;
            fill:#fff;
            position: absolute;
            left: 39.5%;
            top: 75%;
        }
        .button {
            position: absolute;
            right: 11%;
            bottom: 0%;
        }
    }
    .hero__text {
        text-align: center;
        background-position:center;
        background-repeat: no-repeat;
        background-size: 50%;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        margin-top: rem(350px);
        padding-top: rem(150px);
        padding-bottom: rem(150px);
        @include media-max(1200px) {
            margin-top: rem(250px);
        }
        @include media-max(960px) {
            background-size: 100%;
            margin-top: rem(150px);
            padding-top: rem(50px);
            padding-bottom: rem(50px);
        }
        @include media-max(450px) {
            background-size: cover;
            font-size: rem(40px);
        }
    }
}

.circles__bg_abs {
    position: absolute;
    top:50vh;
    right:0;
    left:0;
    width:100%;
    z-index: -1;

    @include media-max(960px) {
        display:none;
    }
}
.empower {
    background-size: cover;
    background-position-x: center;
    background-position-y: -50vh;
    background-repeat: no-repeat;
    background-image: url('../../assets/img/empower_black.png');
    
    @include media-max(1200px) { 
        background-size: contain;
    }
    @include media-max(960px) {
        background-position-y: top;
    }
    
    &.uk-sticky.uk-active.uk-sticky-fixed {
        position: static !important;
        background-size: cover;
        background-position-x: center;
        background-position-y: -50vh;
        background-attachment: fixed;
        background-repeat: no-repeat;

        @include media-max(960px) {
            background-position-y: top;
        }
        @include media-max(640px) {
            background-position-y: top;
            background-size: contain;
        }
        @include media-max(960px) {
            background-position-y: top;
        }
    }
    & ~ .uk-sticky-placeholder {
        height:0 !important;
    }
   

    .come__in__intro {
        position: absolute;
        width:100%;
        height:100%;
        inset:0;
        margin: auto;
        z-index: 3;
        background-color: #000;
        clip-path: circle(100% at 50% 10%);
        -webkit-clip-path: circle(100% at 50% 10%);
        -moz-clip-path: circle(100% at 50% 10%);
        transition: $t-all-mid-slow;

        &.active {
            clip-path: circle(0% at 50% 10%);
            -webkit-clip-path: circle(0% at 50% 10%);
            -moz-clip-path: circle(0% at 50% 10%);
        }
    }
    .grad__circle {
        position: absolute;
        right:0;
        left:0;
        margin: auto;
        top:rem(-360px);
        width:rem(940px);
        pointer-events: none;
    }
    .come__in {
        text-align: center;
        padding-top:11vh;
    }
    .circles__bg {
        width: 100%;
        height:100vh;
        object-fit: cover;
        inset:0;
        margin: auto;
        z-index: -1;
        opacity:0;
        transition: $t-all;

        &.uk-sticky.uk-active.uk-sticky-fixed {
            opacity:1;
        }
    }

    .empower__content {
        width:100%;
        overflow: hidden;
        position: relative;
        isolation: isolate;
        padding-bottom:rem(200px);
        
        .button__container{
            animation-duration: $empowerAnimationTime;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        
        .circles__bg {
            width:100%;
            z-index: -1;
        }
        .tagline {
            position: relative;
            z-index:9;
            text-align: center;

            @include media-min(961px) {
                br {
                    display: none !important;
                }
            }

            .t100 {
                margin-top: 20vh;
                margin-bottom: 20vh;
            }
        }
        .button__container {
            z-index: 3;
            text-align: center;
        }
    }
}
.mspartner {
    background-size: contain;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-color: #000;
    display:grid;
    width:100%;
    
    @include media-max(640px) {
        background-image: url('../assets/img/laptop-bg-mobile.png') !important;
        background-position: center bottom;
    }
    .wrapper {
        width:50%;
        height:100%;
        padding-left:rem(310px);
        display:grid;

        @include media-max(1200px) {
            padding-left: 5vw;
        }
        @include media-max(640px) {
            width:90%;
            padding-left:5%;
            padding-right:5%;
        }

        .msinfo {
            display:grid;
            height:100%;
            grid-gap:rem(85px);
            padding-top:rem(220px);    
            padding-bottom:rem(220px);
            
            @include media-max(640px) {
                grid-gap:rem(55px);
                padding-top:rem(100px);    
                padding-bottom:rem(500px);    

            }
        }
    }
    .mspattern {
        position: absolute;
        inset:0;
        left:auto;
        right:rem(90px);
        height:calc(100% - 180px);
        margin:auto;
        z-index: 9;
        display: flex;
        align-items: center;
        align-content: center;

        img {
            width:rem(700px);

            @include media-max(1200px) {
                width:rem(400px);
            }
        }
    }
}
.optimize {
    background-size: cover;
    background-position: right center;
    display:grid;
    width:100%;
    
    @include media-max(1200px) {
        background-position: 48% center;
    }
    @include media-max(960px) {
        background-position: 62% center;
    }
    @include media-max(640px) {
        background-position: center bottom !important;
        background-image: url('../assets/img/telemetric-bg-mobile.jpg') !important;
    }

    .wrapper {
        width:50%;
        height:100%;
        padding-left:rem(170px);
        display:grid;
        
        @include media-max(1200px) {
            padding-left:rem(70px);
        }
        @include media-max(640px) {
            width:90%;
            padding-left:5%;
            padding-right:5%;
        }

        .optimize__content {
            display:grid;
            height:100%;
            grid-gap:rem(45px);
            padding-top:rem(220px);    
            padding-bottom:rem(220px);
            
            @include media-max(640px) {
                padding-bottom:rem(120px);
            }
        }
    }
    .optimize__pattern {
        position: absolute;
        inset:0;
        left:auto;
        right:rem(315px);
        height:calc(100% - 180px);
        margin:auto;
        z-index: 9;
        display: flex;
        align-items: center;
        align-content: center;

        @include media-max(1200px) {
            right:rem(100px);
        }
        @include media-max(960px) {
            right:rem(40px);
        }
        @include media-max(640px) {
            position:static;
            padding-bottom:rem(255px);
        }

        img {
            width:rem(536px);

            @include media-max(1200px) {
                width:rem(300px);
            }
        }
    }
}
.streamax {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    isolation: isolate;
    z-index: 9;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: rem(220px);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%);
        position: absolute;
        z-index: 0;
        right: 0;
        left: 0;
        bottom: 0;
        
        @include media-max(640px) {
            height: rem(80px);
        }
    }

    @include media-max(640px) {
        padding-top:rem(80px);
    }

    .button__container {
        position: absolute;
        right:0;
        bottom: 0;
        left:0;
        margin: auto;
        @extend .padding_bottom_100;
    }

    .streamax__title {
        position:absolute;
        inset:0;
        margin:auto;
        height:370px;
        z-index: -1;
        transition: $t-all-slow;
        transform: translate(0,0);

        @include media-max(640px) {
            position: static;
            transform: translate(0,0) !important;
            height:auto;
        }
        
        &.active {
            transform: translate(0,-30px);
            svg {
                text {
                    fill: transparent;
                    stroke: #fff;
                    opacity:0.2;
                }
            }
        }

        svg {
            font-family: 'DM Sans';
            font-weight: 700;
            font-size:rem(75px);
            width: 100%;
            transition: $t-all-slow;
        }
        text { 
            fill:#fff;
            stroke-width: 0.2;
            opacity:1;
            stroke: transparent;
            stroke-linejoin: round;
            transition: $t-all-slow;
            
            @include media-max(640px) {
                stroke-width: 0.5;
                opacity:0.5 !important;
            }
        }
    }

    .streamax__content {
        position:absolute;
        width:100%;
        height:100%;

        @include media-max(640px) {
            position:static;
        }
        
        .icons__wrapper {
            position:absolute;
            width:rem(488px);
            height:rem(337px);
            inset:0;
            margin:auto;
            
            @include media-max(1200px) {
                width:rem(300px);
                height:rem(217px);
            }
            @include media-max(960px) {
                width:rem(220px);
                height:rem(200px);
            }
            @include media-max(640px) {
                position:static;
                width:rem(340px);
                height:auto;
                padding-bottom:rem(130px);
            }

            .button__container {
                margin-top:rem(70px);
            }
            
            .center__image {
                position: absolute;
                inset:0;
                margin:auto;
                width:rem(488px);
                z-index: 9;
                
                @include media-max(1200px) {
                    width:rem(300px);
                }
                @include media-max(960px) {
                    width:rem(220px);
                }
                @include media-max(640px) {
                    position:static;
                    padding-top:rem(25px);
                    padding-bottom:rem(50px);
                    display: block;
                    width:100%;
                }
            }
            
            .icon {
                position: absolute;
                display:flex;
                align-items: center;
                align-content: center;
                gap:rem(20px);
                
                @include media-max(640px) {
                    position:static;
                    padding-top:rem(20px);
                    padding-bottom:rem(20px);
                }

                &.text__left {
                    text-align: right;

                    @include media-max(640px) {
                        text-align: left;
                    }
                }
                &.text__right {
                    flex-direction: row-reverse;
                    
                    @include media-max(640px) {
                        text-align: left;
                        flex-direction: row;
                    }
                }

                .icon__desc {
                    @include media-max(640px) {
                        order:2;
                    }
                }
                .icon__image {
                    @include media-max(640px) {
                        min-width:rem(70px);
                        order:1;
                    }

                    &:after {
                        
                        @include media-max(640px) {
                            display: none !important;
                        }
                    }
                }


                &:nth-of-type(2) {
                    left:rem(-190px);
                    top:rem(-170px);
                    margin: auto;
                    
                    @include media-max(960px) {
                        left:rem(-160px);
                        top:rem(-120px);
                    }
                    
                    .icon__image {
                        position: relative;

                        &:after {
                            content:'';
                            display:block;
                            width:1px;
                            height:rem(150px);
                            background-color: $green;
                            right:0;
                            left:0;
                            margin:auto;
                            position: absolute;
                        }
                    }
                }
                &:nth-of-type(3) {
                    right:rem(-100px);
                    top:rem(-120px);
                    margin: auto;
                    
                    @include media-max(960px) {
                        right:rem(-110px);
                        top:rem(-80px);
                    }
                    
                    .icon__image {
                        position: relative;

                        &:after {
                            content:'';
                            display:block;
                            width:1px;
                            height:rem(150px);
                            background-color: $green;
                            right:0;
                            left:0;
                            margin:auto;
                            position: absolute;
                        }
                    }
                }
                &:nth-of-type(4) {
                    left:rem(-260px);
                    top:rem(180px);
                    margin: auto;
                    
                    @include media-max(960px) {
                        left:rem(-190px);
                        top:rem(100px);
                    }
                    
                    .icon__image {
                        position: relative;

                        &:after {
                            content:'';
                            display:block;
                            width:rem(120px);
                            height:1px;
                            background-color: $green;
                            top:0;
                            bottom:0;
                            left:100%;
                            margin:auto;
                            position: absolute;
                        }
                    }
                }
                &:nth-of-type(5) {
                    left:rem(40px);
                    bottom:rem(-90px);
                    margin: auto;
                    
                    @include media-max(960px) {
                        left:rem(10px);
                        bottom:rem(-110px);
                    }
                    
                    .icon__image {
                        position: relative;

                        &:after {
                            content:'';
                            display:block;
                            width:1px;
                            height:rem(100px);
                            background-color: $green;
                            right:0;
                            left:0;
                            bottom:100%;
                            margin:auto;
                            position: absolute;
                        }
                    }
                }
            }
        }

        .streamax__info {
            position:absolute;
            left:65%;
            top:35%;
            // transform: translate(rem(300px), -50%);
            
            @include media-max(960px) {
                left:68%;
                top:40%;

                .t40 {
                    font-size:rem(26px) !important;
                }
            }

            .button__container {
                margin-top: rem(40px);
            }
        }
    }
}

// stay secured
.staysecured {
    isolation: isolate;
    padding-top:rem(140px);
    padding-bottom:rem(80px);
    overflow: hidden;
    background-color: #000;

    .staysecured_grad_text {
        background: url('../../assets/img/staysecured_grad_text.png');
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .blue__grad {
        background: linear-gradient(94.07deg, #4200FF 10%, #00A4EF 53.5%, #00F0FF 102.66%);
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .green__grad {
        background: linear-gradient(94.07deg, #88FC6C 10%, #00FFAC 53.5%, #C7FF2A 102.66%);
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .staysecured__img {
        position: absolute;
        inset:0;
        margin: auto;
        mix-blend-mode: lighten;
        max-width: rem(600px);

        @include media-max(960px) {
            top: auto;
            bottom:rem(100px);
            max-width: rem(400px);
        }
    }

    .wrapper {
        max-width: rem(1280px);
        margin-right: auto;
        margin-left: auto;

        &.center {
            z-index: 9;
        }

        @include media-max(1440px) {
            max-width: 90%;
        }
        @include media-max(640px) {
            padding-bottom: rem(60px);
        }
    }
    .secondary  {
        margin-bottom: rem(180px);
        
        @include media-max(960px) {
            transform-origin: 100% 0;
            transform: scale(0.5);
            margin-bottom: rem(0px);
        }

        @include media-max(640px) {
            margin-bottom:rem(-80px);
        }
    }

    .section__bg {
        position: absolute;
        inset:0;
        margin:auto;
        z-index: -1;

        @include media-max(960px) {
            position: static;
        }

        img {
            width:100vh;
        }
    }
    .staysecured__content {
        margin-top:rem(100px);
        max-width: 60%;
        margin-left: auto;
        
        a.button {
            display: inline-flex;
        }
        
        @include media-max(960px) {
            max-width: unset;
            margin-left: auto;
            margin-right: auto;
        }
        @include media-max(640px) {
            margin-top:rem(40px);
        }
    }
    .button__container {
        margin-top:rem(70px);

        @include media-max(640px) {
            margin-top:rem(40px);
        }
    }

    .grid__1_1 {
        @include media-max(960px) {
            grid-template-columns: 1fr;
        }
    }
}
.divein {
    padding-top:rem(180px);
    background-color: #000;

    .dot {
        background-color: $green;
        width:rem(30px);
        height:rem(30px);
        display: inline-block;
        border-radius: 1000px;
    }
    .t80, .tagline, .appendix {
        z-index: 9;
    }
    .tagline {
        margin-top:rem(60px);
    }
    .divein__content {
        & > div:not(.divein__graphic) {
            position: relative;
            z-index: 9;
        }

    }
    .divein__graphic {
        position: relative;
        margin: rem(-80px) auto 0;
        z-index: 1;

        @include media-max(640px) {
            img {
                width:330vw;
                max-width: 100000px;
                margin-left: -115vw;
            }
        }

        .button__container {
            position: absolute;
            top:60%;
            right:0;
            left:0;
            margin:auto;
        }
    }
}
.team {
    background-color: #000;

    .wrapper {
        max-width: 1280px;
        margin-right: auto;
        margin-left: auto;

        @include media-max(1440px) {
            max-width: 90%;
        }
    }

    .team__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        margin-bottom: rem(100px);
    }
    .team__slider {
        position: relative;
        padding-bottom:rem(100px);

        .noodle__left {
            position: absolute;
            bottom:0;
            left:0;
            width:rem(480px);
            pointer-events: none;
            
            @include media-max(1200px) {
                width:rem(310px);
            }
            @include media-max(640px) {
                display: none;
            }
        }
        .noodle__right {
            position: absolute;
            bottom:0;
            right:0;
            width:rem(480px);
            pointer-events: none;

            @include media-max(1200px) {
                width:rem(310px);
            }
            @include media-max(640px) {
                bottom:rem(150px);
            }
        }
    }
}
.hiring {
    background-position: center bottom;
    background-color: #000;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top:rem(60px);
    padding-bottom:rem(250px);
    
    @include media-max(960px) {
        padding-bottom:rem(180px);
    }

    .hiring__content {
        display: grid;
        grid-template-columns: 1fr 1fr;

        & > div {
            grid-column: 2/-1;

            @include media-max(640px) {
                grid-column: 1/-1;
                width:90%;
                margin-right: 5%;
                margin-left: 5%;
            }
        }
    }

    .list {
        margin-bottom: rem(60px);

        & > div {
            margin-bottom: rem(15px);
        }
    }
}
.cards {
    isolation: isolate;
    padding-top:rem(400px);
    padding-bottom:rem(600px);
    overflow-y: hidden;
    background-color: #000;
    
    @include media-max(960px) {
        padding-top:rem(200px);
        padding-bottom:rem(400px);
    }

    .wrapper {
        max-width: 1440px;
        margin-right: auto;
        margin-left: auto;
        
        @include media-max(1500px) {
            max-width: 1280px;
        }
        @include media-max(1440px) {
            max-width: 90%;
        }
    }
    .blur1 {
        position: absolute;
        top:0;
        left:0;
        transform: translate(-50%, -20%);
        z-index: -1;
    }
    .blur2 {
        position: absolute;
        z-index: -1;
        top:0;
        left:50%;
        transform: translate(-50%, 20%);
    }
    .blur3 {
        position: absolute;
        z-index: -1;
        bottom:0;
        left:50%;
        transform: translate(-50%, 10%);
    }
    .cards__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:rem(25px);

        @include media-max(960px) {
            grid-template-columns: 1fr;
        }

        .card {
            position: relative;
            border-radius: 54px;
            overflow-y: hidden;
            
            @include media-max(960px) {
                border-radius: 70px;
                box-shadow:0 0 20px 5px #37722961;
            }

            &:not(.card__small) {
                @include media-max(960px) {
                    min-height: 1160px;
                }
                @include media-max(640px) {
                    min-height: rem(780px);
                }
            }

            &.with__dots {
                .dots {
                    width:100%;
                    position: relative;
                }
                .dots:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
                @for $i from 1 through 70 {
                    .dot:nth-child(#{$i}) {
                        top: percentage(random(100) / 100);
                        left: percentage(random(100) / 100);
                    }
                }
                .dot {
                    width:9px;
                    height:9px;
                    background-color: $green;
                    border-radius: 1000px;
                    position:absolute;
                }
            }

            .card__content {
                position: absolute;
                top:0;
                padding:rem(30px);
                width:100%;

                &.bottom__content {
                    width:100%;
                    height:100%;
                    display: flex;
                    justify-content: center;
                    justify-items: center;
                    align-content: flex-end;
                    align-items: flex-end;
                }

                &.center__content {
                    height:100%;
                    width:100%;
                    display: flex;
                    flex-direction: column;
                    gap:rem(30px);
                    align-items: center;
                    align-content: center;
                    justify-items: center;
                    justify-content: center;
                }

            }
            & > img {
                width: 100%;
            }
        }
    }
}
.cases {
    background-color: #000;

    @include media-max(640px) {
        min-height: 1px;
    }
    .uk-slideshow-items {
        @include media-max(960px) {
            min-height: 75vh !important;
        }
    }
    .case__item {
        .case__inner {
            position:relative;
    
            .item__content {
                position: absolute;
                top:0;
                right:0;
                left:0;
                max-width: 1380px;
                margin-right: auto;
                margin-left: auto;
                height:100%;
                display: flex;
                align-items: center;
                align-content: center;

                @include media-max(1500px) {
                    max-width: 1280px;
                }
                @include media-max(1440px) {
                    max-width:90%;
                }
                @include media-max(960px) {
                    align-items: flex-start;
                    align-content: flex-start;
                    padding-top:rem(20px);
                }
   
                .desc {
                    max-width: 45%;
                }
                .button__container {
                    margin-top: rem(60px);
                }

                @include media-max(960px) {
                    .t180 {
                        font-size:rem(70px);
                    }
                    .desc {
                        max-width: 85%;
                    }
                    .button__container {
                        margin-top: rem(30px);
                    }
                }
                @include media-max(640px) {
                    .t180 {
                        font-size:rem(40px);
                    }
                    .t50 {
                        font-size:rem(20px);
                    }
                    .desc {
                        max-width: 85%;
                    }
                    .button__container {
                        margin-top: rem(30px);
                    }
                }
            }
            .bar__borders {
                border-top:1px solid $grey80;
                border-bottom:1px solid $grey80;
            }
            .case__bar {
                max-width: 1380px;
                margin-right: auto;
                margin-left: auto;
                background-color: #000;
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                min-height: rem(100px);

                @include media-max(1500px) {
                    max-width: 1280px;
                }
                @include media-max(1440px) {
                    max-width:90%;
                }
                @include media-max(960px) {
                    flex-wrap: wrap;
                    gap:rem(20px)
                }
                @include media-max(640px) {
                    flex-wrap: wrap;
                    gap:rem(10px)
                }

                & > div {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    gap:rem(50px);

                    @include media-max(640px) {
                        flex-wrap: wrap;
                        gap:15px;
                    }

                    & > span:first-child {
                        @include media-max(960px) {
                            min-width:160px;
                        }
                        @include media-max(640px) {
                            min-width:100px;
                        }
                    }
                }
            }
        }
    }
}
.azure__pattern {
    width:rem(700px);
    position: relative;
    pointer-events: none;

    @include media-max(1200px) {
        width:rem(400px);
    }
    @include media-max(960px) {
        width:rem(250px);
    }
    @include media-max(640px) {
        display:none;
    }

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    .pattern__grid {
        position: absolute;
        inset:0;
        width:100%;
        height:100%;
        display:grid;
        grid-template-columns: repeat(11, minmax(0,1fr));
        grid-template-rows: repeat(11, minmax(0,1fr));
    }
    .text__layer {
        position: absolute;
        inset:0;
        width:100%;
        height:100%;
        display: grid;
        grid-template-rows: repeat(11, minmax(0,1fr));
        align-content: center;
        align-items: center;

        .text__row {
            display: flex;
        }
        .text {
            position: relative;
            opacity:0;
            transform: translate(-50px, 0);
            transition: $t-all;
            transition-duration: .75s;

            @include media-max(1200px) {
                font-size: rem(28px) !important;
            } 
            @include media-max(960px) {
                font-size: rem(18px) !important;
            } 
        }
        .text1 {
            left:rem(240px);
            
            @include media-max(1200px) {
                left:rem(140px);
            }
            @include media-max(960px) {
                left:rem(87px);
            }
        }
        .text2 {
            left:rem(210px);

            @include media-max(1200px) {
                left:rem(120px);
            }
            @include media-max(960px) {
                left:rem(75px);
            }
        }
        .text3 {
            left:rem(460px);

            @include media-max(1200px) {
                left:rem(265px);
            }
            @include media-max(960px) {
                left:rem(164px);
            }
        }
        .text4 {
            left:rem(230px);
            
            @include media-max(1200px) {
                left:rem(135px);
            }
            @include media-max(960px) {
                left:rem(80px);
            }
        }
        .text5 {
            left:rem(370px);

            @include media-max(1200px) {
                left:rem(210px);
            }
            @include media-max(960px) {
                left:rem(132px);
            }
        }
        .text6 {
            left:rem(300px);

            @include media-max(1200px) {
                left:rem(175px);
            }
            @include media-max(960px) {
                left:rem(105px);
            }
        }
    }
    
    .element {
        border:2px solid #fff;
        width:100%;
        position: relative;
        border-radius:1000px;
        transition: all .75s cubic-bezier(0.440, -0.600, 0.210, 1.385);
        transition-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);

        &.move__left {
            transform: translate(0%, 0);
            
            &.far {
                transform: translate(0%, 0);
            }
        }
        &.move__right {
            transform: translate(0%, 0);
            
            &.far {
                transform: translate(0%, 0);
            }
            &.far2 {
                transform: translate(0%, 0);
            }
            &.short {
                transform: translate(0%, 0);
            }
        }

        &.wide {
            grid-column: span 3;

            &.move__right {
                transform: translate(0%, 0);
                
                &.far {
                    transform: translate(0%, 0);
                }
                &.far2 {
                    transform: translate(0%, 0);
                }
                &.short {
                    transform: translate(0%, 0);
                }
            }
            &.move__left {
                transform: translate(0%, 0);
            }
        }

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }
    .gap {
        grid-column: 1/-1;
    }

    &.animate {
        .element {

            &.make__green {
                border-color: $green;
            }
            &.move__left {
                transform: translate(-200%, 0);
                
                &.far {
                    transform: translate(-600%, 0);
                }
            }
            &.move__right {
                transform: translate(200%, 0);
                
                &.far {
                    transform: translate(300%, 0);
                }
                &.far2 {
                    transform: translate(400%, 0);
                }
                &.short {
                    transform: translate(100%, 0);
                }
            }
    
            &.wide {
                &.move__right {
                    transform: translate(66.66%, 0);
                    
                    &.far {
                        transform: translate(100%, 0);
                    }
                    &.far2 {
                        transform: translate(133.33%, 0);
                    }
                    &.short {
                        transform: translate(33.33%, 0);
                    }
                }
                &.move__left {
                    transform: translate(-66.66%, 0);
                }
            }
        }
        .text__layer {
            .text {
                transform: translate(0,0);
                opacity:1;
            }
        }
    }
}
.telemetric__pattern {
    width:rem(530px);
    position: relative;
    pointer-events: none;

    @include media-max(1200px) {
        width:rem(400px);
    }
    @include media-max(960px) {
        width:rem(250px);
    }

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    .pattern__grid {
        position: absolute;
        inset:0;
        width:100%;
        height:100%;
        display:grid;
        grid-template-columns: repeat(11, minmax(0,1fr));
        grid-template-rows: repeat(11, minmax(0,1fr));
    }
  
    .element {
        border:2px solid transparent;
        background: #fff;
        width:100%;
        position: relative;
        border-radius:1000px;
        transition: all .75s cubic-bezier(0.440, -0.600, 0.210, 1.385);
        transition-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);

        &.move__left {
            transform: translate(0%, 0);
            
            &.far {
                transform: translate(0%, 0);
            }
        }
        &.move__right {
            transform: translate(0%, 0);
            
            &.far {
                transform: translate(0%, 0);
            }
            &.far2 {
                transform: translate(0%, 0);
            }
            &.short {
                transform: translate(0%, 0);
            }
        }

        &.wide {
            grid-column: span 3;
            
            &.xwide {
                grid-column: span 4;
            }

            &.move__right {
                transform: translate(0%, 0);
                
                &.far {
                    transform: translate(0%, 0);
                }
                &.far2 {
                    transform: translate(0%, 0);
                }
                &.short {
                    transform: translate(0%, 0);
                }
            }
            &.move__left {
                transform: translate(0%, 0);
            }
        }
        &.tall {
            grid-row:span 3;
            
            &.xtall {
                grid-row:span 4;
            }
        }

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }
    .gap {
        grid-column: 1/-1;

        &.gap__m-one {
            grid-column: 1/-2;
        }
        &.gap__p-one {
            grid-column: 2/-1;
        }
        &.gap__short {
            grid-column: 2/-2;
        }
    }

    .cross__layer {
        transform: rotate(45deg);
        transform-origin: 50% 50%;

        .element {
            &.elem1 {
                transform: translate(0, 15%);
            }
            &.elem2 {
                transform: translate(15%, 0);
            }
            &.elem3 {
                transform: translate(-15%, 0);
            }
            &.elem4 {
                transform: translate(0, -15%);
            }
        }
    }
    .text__layer {
        position: absolute;
        top:75%;
        left:95%;
        width:100%;
    }
    .fadein {
        transform: translate(5%, 0);
        opacity: 0;
        transition: all .75s cubic-bezier(0.440, -0.600, 0.210, 1.385);
        transition-timing-function: cubic-bezier(0.440, -0.600, 0.210, 1.385);
    }
    &.animate {
        .fadein {
            transform: translate(0%, 0);
            opacity: 1;
        }
        .element {
            background-color: transparent;
            border-color: #fff;

            &.make__green {
                border-color: $green;
            }
            &.move__left {
                transform: translate(-200%, 0);
                
                &.far {
                    transform: translate(-600%, 0);
                }
            }
            &.move__right {
                transform: translate(200%, 0);
                
                &.far {
                    transform: translate(300%, 0);
                }
                &.far2 {
                    transform: translate(400%, 0);
                }
                &.short {
                    transform: translate(100%, 0);
                }
            }
            &.move__up {
                transform: translate(0, -200%);
            }
            &.move__down {
                transform: translate(0, 200%);
            }
            &.move__down66 {
                transform: translate(0, 66.66%);
            }
            &.move__left66 {
                transform: translate(-66.66%, 0);
            }
            &.move__up400 {
                transform: translate(0, -400%);
            }
            &.move__down400 {
                transform: translate(0, 400%);
            }
            &.move__left400 {
                transform: translate(-400%, 0);
            }
            &.move__right400 {
                transform: translate(400%, 0);
            }
            &.move__right600 {
                transform: translate(600%, 0);
            }
            &.move__left600 {
                transform: translate(-600%, 0);
            }
            &.fadeout {
                transform: translate(10%,0);
                opacity:0;
            }
    
            &.wide {
                &.move__right {
                    transform: translate(66.66%, 0);
                    
                    &.far {
                        transform: translate(100%, 0);
                    }
                    &.far2 {
                        transform: translate(133.33%, 0);
                    }
                    &.short {
                        transform: translate(33.33%, 0);
                    }
                }
                &.move__left {
                    transform: translate(-66.66%, 0);
                }
            }
        }
        .cross__layer {
            .element {
                &.elem1 {
                    transform: translate(0, 0%);
                }
                &.elem2 {
                    transform: translate(0%, 0);
                }
                &.elem3 {
                    transform: translate(0%, 0);
                }
                &.elem4 {
                    transform: translate(0, 0%);
                }
            }
        }
    }
}

.duration_2 {
    animation-duration: 2s;
}
.duration_1_5 {
    animation-duration: 1.5s;
}
.duration_1 {
    animation-duration: 1s;
}