* {
  box-sizing: border-box;
  &::before, &::after {
    @extend *;
    speak: none;
  }
}
html {
  margin: 0;
  padding: 0;
  font-size: 3.8333vw;
  font-family: $font-family;
  font-weight: 400;
  background-color: #000;
  color: #fff;
  // overflow-x:hidden;
  @include media-min(450px) {
    font-size: 2.8vw;
  }
  @include media-min($sm) {
    font-size: 2.2vw;
  }
  @include media-min($md) {
    font-size: 1.4vw;
  }
  @include media-min($lg) {
    font-size: .833334vw;
  }
}
body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // overflow-x:hidden;
  font-size:rem(16px);
}
main {
  flex-grow: 1;
  // overflow-x:hidden;

}
section {
  min-height: 100vh;
  position: relative;
  overflow-y: hidden;

  &:not(.theway) {
    overflow-x: hidden;
    max-width: 100vw;
  }

  &.standard__height {
    min-height: 1px;
  }
}
a {
  color: inherit;
  transition: $t-all;
  will-change: color;
  @include hover {
    color: $link;
    text-decoration: none;
  }
}
b,
strong
{
  font-weight: 700;
}
em {
  color: inherit;
}
// Set inherited font family
input,
button,
textarea,
select,
option {
  font-family: inherit;
  &::placeholder {
    color: inherit;
  }
}
button,
select,
input[type="submit"] {
  color: inherit;
  cursor: pointer;
  @include hover {
    cursor: pointer;
  }
}
textarea {
  overflow: auto;
}
[type=checkbox] {
  cursor: pointer;
}
@include headings {
  font-family: inherit;
  color: inherit;
}
img {
  max-height: inherit;
  &[uk-svg] {
    display: none;
  }
  // Prevents img without src to appear
  &:not([src]) {
    visibility: hidden;
  }
  //MS Edge fix for lazyload
  &[data-src],
  &[data-srcset] {
    display: block;
    min-height: 1px;
  }
}
svg {
  @include svg-color(inherit);
  max-width: inherit;
  max-height: inherit;
}
:not(pre) > code, :not(pre) > em, :not(pre) > kbd, :not(pre) > samp {
  color: #000000;
}
//overwrite uikit
.uk-lightbox {
  background-color: #000000b8 !important;
}
.uk-lightbox-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  transition: $t-bc, $t-color;
  padding: rem(5px);
  &:hover, &:focus {
    background-color: rgba(0, 0, 0, 0.25);
    color: rgba(102, 102, 102, 0.9);
  }
  &::after {
    content: "";
    display: block;
    width: rem(40px);
    height: rem(40px);
    background:{
      image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' data-svg='chevron-right'%3E%3Cpolyline fill='none' stroke='%23fff' stroke-width='1.03' points='7 4 13 10 7 16'%3E%3C/polyline%3E%3C/svg%3E");
      position: center;
      repeat: no-repeat;
      size: 80%;
    }
  }
  &.uk-slidenav-previous {
    &::after {
      background:{
        image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' data-svg='chevron-left'%3E%3Cpolyline fill='none' stroke='%23fff' stroke-width='1.03' points='13 16 7 10 13 4'%3E%3C/polyline%3E%3C/svg%3E");
      }
    }
  }
}

.readers-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  padding: 0;
  margin: 0;
}
.mono {
  font-family: 'DM Mono', serif;
}
.appendix {
  img {
    width:rem(48px);
    margin-right:rem(3px);
  }
  font-size:rem(40px);
}
a {
  svg, path {
      transition: $t-all;
  }
}