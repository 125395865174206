.button {
    display: inline-block;
    padding:rem(15px 45px);
    background-color: #fff;
    color:#000;
    border-radius:1000px;
    font-size:rem(16px);
    background-image: linear-gradient(
        90deg, 
        #fff,
        #fff,
        #000,
        #88FC6C,
        #C7FF2A
    );
    background-size: 400%;
    background-position: left;
    transition: $t-all-slow;
    
    @include media-max(1200px) {
        padding:rem(10px 30px);
        font-size:rem(13px);
    }
    
    &.in__button {
        font-size:1.5rem !important;

        img {
            width:rem(35px) !important;
        }
    }
    &:hover {
        color:#000;
        background-position: right;
    }

    &.green {
        background-image: none;
        background-color: $green;
        color: #000;
        border: 1px solid $green;

        &:hover {
            background-color: #000;
            color: $green;
        }

        &.outline {
            background-color: transparent;
            color: $green;
            border: 1px solid $green;

            &:hover {
                background-color: $green;
                color: #000;
            }
        }
    }
    &.small {
        padding:rem(10px 32px);
    }
}